﻿@import '../shared/palette';
@import '../shared/_mixins';

#ip_page {

    .hero-section {


        .cmg-container {
            display: flex;
            flex-direction: column-reverse;
            overflow-x: hidden;
        }

        .banner-text {
            padding-top: 0px;
            padding-bottom: 68px;

            h1 {
                font-family: 'Kadwa', serif;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0px;
                font-weight: 700;
                color: #175A80;
                margin-bottom: 0px;
            }

            h2 {
                font-size: 16px;
                color: #4D4D4D;
                font-weight: normal;
                margin-bottom: 28px;
                line-height: 24px;
                padding-top: 16px;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }

            .list-items {
                ul {
                    list-style-type: none;
                    list-style-position: outside;
                    margin-inline-start: 0px;
                    padding-inline-start: 0px;
                    margin-bottom: 0px;
                    // list-style-image: url(https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1608734277/AIOWebsite/Partners_Images/check.svg);
                }

                ul li {
                    display: flex;
                    margin-bottom: 19px;

                    span {
                        margin-top: -5px;
                        font-family: 'Open Sans', sans-serif;
                        color: #4D4D4D;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        padding-left: 13.23px;
                    }

                    img {
                        width: 14.15px;
                        height: 13.59px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .banner-img {
            padding-top: 0px;
            margin: 0px -32px;

            img {
                width: 100%;
                height: 255px;
            }
        }

        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
        }

        @media only screen and (min-width: 480px) {
            .cmg-container {
            }

            .banner-text {
                padding-bottom: 50px;

                h1 {
                    font-size: 28px;
                    line-height: 46px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 28px;
                    padding-top: 0px;
                }

                p {

                    &:first-of-type {
                        // margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-bottom: 43px;
                    }
                }
            }

            .banner-img {
                margin: 0px -60px;

                img {
                    height: auto;
                }
            }
        }

        @media only screen and (min-width: 768px) {
            background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Borrower_Images/768_Image.png");
            background-size: cover;
            // background-position: 80px center;
            .cmg-container {
                flex-direction: row;
            }

            .banner-text {
                padding-top: 70px;
                padding-bottom: 90px;

                h2 {
                    margin-bottom: 34.96px;
                    max-width: 457px;
                }

                p {
                    max-width: 457px;

                    &:last-of-type {
                        margin-bottom: 42px;
                    }
                }
            }

            .banner-img {
                display: none;
                position: relative;

                img {
                    width: 768px;
                    height: 100%
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
            background-position: 10% center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            // background-position: 180px center;
            .cmg-container {
                flex-direction: row;
                padding-top: 0px;
                padding-bottom: 0px;
                display: flex;
                justify-content: space-between;
            }

            .banner-text {
                padding-top: 66px;
                padding-bottom: 63px;
                width: 470px;
                margin-right: -100px;

                h2 {
                    margin-bottom: 35px;
                }

                p {
                    max-width: 450px;
                }

                .list-items {
                    margin-right: -10px;
                }
            }

            .banner-img {
                display: block;
                padding-top: 0px;
                position: relative;
                //width: 600px;
                margin: unset;
                width: 649px;
                margin-right: -60px;

                img {
                    position: absolute;
                    bottom: 0px;
                    width: 649px;
                    height: 100%;
                }
            }
        }

        @media only screen and (min-width: 1200px) {
            //  background-position: 60px center;
            .cmg-container {
                padding-top: 0px;
                padding-bottom: 0px;
            }


            .banner-text {
                padding-top: 110px;
                padding-bottom: 110px;
                margin-right: 0px;

                h1 {
                    font-size: 28px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 35px;
                    max-width: 446px;
                }

                p {
                    max-width: 446px;

                    &:last-of-type {
                        margin-bottom: 42px;
                    }
                }

                .list-items {
                    ul li {
                        span {
                        }
                    }
                }
            }

            .banner-img {
                margin-right: -100px;
                width: 756px;

                img {
                    width: 756px;
                    height: 100%;
                }
            }
        }

        @media only screen and (min-width: 1440px) {
            //background-position: 60px center;
            .cmg-container {
            }


            .banner-text {
                width: 547px;
                padding-top: 89px;
                padding-bottom: 98px;

                h1 {
                    font-size: 40px;
                    line-height: 50px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 29px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 100%;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 38px;
                    }
                }

                .list-items {
                    ul {
                    }

                    ul li {
                        img {
                            width: 17.7px;
                            height: 17.07px;
                        }

                        span {
                            margin-top: -4px;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .banner-img {
                margin-right: -140px;
            }
        }

        @media only screen and (min-width: 1920px) {
            //background-position: 60px center;
            .cmg-container {
            }


            .banner-text {
                padding-top: 126px;
                padding-bottom: 128px;

                h1 {
                    font-size: 40px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 29px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 38px;
                    }
                }

                .list-items {

                    ul li {

                        span {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .banner-img {
                margin-right: -278px;
                width: 1006px;

                img {
                    width: 1006px;
                    height: 100%;
                    // left: 100px;
                }
            }
        }

        @media only screen and (min-width: 2560px) {
            .banner-text {
                padding-top: 238px;
                padding-bottom: 224px;
            }

            .banner-img {
                width: 1416px;
                margin-right: -524px;

                img {
                    width: 1416px;
                }
            }
        }
    }
}
