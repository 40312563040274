﻿#testimonialwidget-partial-view {
    .testimonial-section {
        background-color: #175A80;
        padding-top: 69px;
        padding-bottom: 69px;


        .cmg-container {
            display: flex;
            flex-direction: column;
        }

        .testimonial-item {
            width: 100%;

            .icon {
                margin-bottom: 16px;

                img {
                    width: 30px;
                    height: 24px;
                }
            }

            .content {
                h2 {
                    font-family: 'Open Sans', sans-serif;
                    color: #80B2CE;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 22px;
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'Open Sans', sans-serif;
                    color: #FFFFFF;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: .5rem;
                }
            }

            &:first-of-type {
                margin-bottom: 68px;
            }
        }

        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
        }

        @media only screen and (min-width: 480px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @media only screen and (min-width: 768px) {

            .testimonial-item {
                display: flex;
                flex-direction: row;

                .icon {
                    margin-right: 29px;

                    img {
                        width: 35px;
                        height: 28px;
                    }
                }


                .content {

                    p {
                        font-size: 14px;
                        line-height: 22px;
                        max-width: 584px;
                    }
                }

                &:first-of-type {
                    margin-bottom: 50px;
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            height: 207px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 0px;
            padding-bottom: 0px;

            .cmg-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .testimonial-item {
                width: 46%;
                padding-bottom: 0px;

                .icon {
                }

                .content {
                    h2 {
                        font-size: 12px;
                        line-height: 24px;
                        letter-spacing: 1.2px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 22px;
                        max-width: 350px;
                    }
                }

                &:first-of-type {
                    margin-bottom: 0px;
                }

                &:last-of-type {
                    justify-content: flex-end;
                }
            }
        }

        @media only screen and (min-width: 1200px) {

            .cmg-container {
                display: flex;
                flex-direction: row;
            }

            .testimonial-item {
                .icon {
                    margin-right: 26px;

                    img {
                        width: 60px;
                        height: 48px;
                    }
                }

                .content {
                    h2 {
                        font-size: 12px;
                        line-height: 22px;
                        letter-spacing: 1.2px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 22px;
                        max-width: 400px;
                    }
                }
            }
        }

        @media only screen and (min-width: 1440px) {


            .testimonial-item {

                .content {
                    h2 {
                        font-size: 12px;
                        line-height: 22px;
                        letter-spacing: 1.2px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        max-width: 446px;
                    }
                }
            }
        }

        @media only screen and (min-width: 1920px) {


            .testimonial-item {

                .content {

                    p {
                        max-width: 500px;
                    }
                }
            }
        }

        @media only screen and (min-width: 2560px) {


            .testimonial-item {

                .content {

                    p {
                        max-width: 500px;
                    }
                }
            }
        }
    }
}
