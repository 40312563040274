#webinar_retail_page .hero-section, #webinar_tpo_page .hero-section, #webinar_sp_page .hero-section, #webinar_jv_page .hero-section {
  background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
  background-position: 10% center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  #webinar_retail_page .hero-section .cmg-container, #webinar_tpo_page .hero-section .cmg-container, #webinar_sp_page .hero-section .cmg-container, #webinar_jv_page .hero-section .cmg-container {
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden; }
  #webinar_retail_page .hero-section .banner-text, #webinar_tpo_page .hero-section .banner-text, #webinar_sp_page .hero-section .banner-text, #webinar_jv_page .hero-section .banner-text {
    padding-top: 29px; }
    #webinar_retail_page .hero-section .banner-text h1, #webinar_tpo_page .hero-section .banner-text h1, #webinar_sp_page .hero-section .banner-text h1, #webinar_jv_page .hero-section .banner-text h1 {
      font-family: 'Kadwa', serif;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0px;
      font-weight: 700;
      color: #175A80;
      margin-bottom: 8px; }
    #webinar_retail_page .hero-section .banner-text h2, #webinar_tpo_page .hero-section .banner-text h2, #webinar_sp_page .hero-section .banner-text h2, #webinar_jv_page .hero-section .banner-text h2 {
      font-size: 16px;
      color: #4D4D4D;
      font-weight: normal;
      margin-bottom: 28px;
      line-height: 24px; }
    #webinar_retail_page .hero-section .banner-text p, #webinar_tpo_page .hero-section .banner-text p, #webinar_sp_page .hero-section .banner-text p, #webinar_jv_page .hero-section .banner-text p {
      font-family: 'Open Sans', sans-serif;
      color: #4D4D4D;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      max-width: 100%; }
      #webinar_retail_page .hero-section .banner-text p span, #webinar_tpo_page .hero-section .banner-text p span, #webinar_sp_page .hero-section .banner-text p span, #webinar_jv_page .hero-section .banner-text p span {
        font-weight: 700;
        font-size: 14px; }
      #webinar_retail_page .hero-section .banner-text p:first-of-type, #webinar_tpo_page .hero-section .banner-text p:first-of-type, #webinar_sp_page .hero-section .banner-text p:first-of-type, #webinar_jv_page .hero-section .banner-text p:first-of-type {
        margin-bottom: 20px; }
      #webinar_retail_page .hero-section .banner-text p:last-of-type, #webinar_tpo_page .hero-section .banner-text p:last-of-type, #webinar_sp_page .hero-section .banner-text p:last-of-type, #webinar_jv_page .hero-section .banner-text p:last-of-type {
        margin-bottom: 50px; }
  #webinar_retail_page .hero-section .banner-img, #webinar_tpo_page .hero-section .banner-img, #webinar_sp_page .hero-section .banner-img, #webinar_jv_page .hero-section .banner-img {
    padding-top: 22px;
    margin: 0px -42px; }
    #webinar_retail_page .hero-section .banner-img img, #webinar_tpo_page .hero-section .banner-img img, #webinar_sp_page .hero-section .banner-img img, #webinar_jv_page .hero-section .banner-img img {
      width: 100%; }
  @media only screen and (min-width: 425px) {
    #webinar_retail_page .hero-section, #webinar_tpo_page .hero-section, #webinar_sp_page .hero-section, #webinar_jv_page .hero-section {
      background-position: 0 center; } }
  @media only screen and (min-width: 480px) {
    #webinar_retail_page .hero-section .banner-text, #webinar_tpo_page .hero-section .banner-text, #webinar_sp_page .hero-section .banner-text, #webinar_jv_page .hero-section .banner-text {
      padding-bottom: 0px; }
      #webinar_retail_page .hero-section .banner-text h1, #webinar_tpo_page .hero-section .banner-text h1, #webinar_sp_page .hero-section .banner-text h1, #webinar_jv_page .hero-section .banner-text h1 {
        font-size: 28px;
        line-height: 40px; }
      #webinar_retail_page .hero-section .banner-text h2, #webinar_tpo_page .hero-section .banner-text h2, #webinar_sp_page .hero-section .banner-text h2, #webinar_jv_page .hero-section .banner-text h2 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 28px; }
      #webinar_retail_page .hero-section .banner-text p:last-of-type, #webinar_tpo_page .hero-section .banner-text p:last-of-type, #webinar_sp_page .hero-section .banner-text p:last-of-type, #webinar_jv_page .hero-section .banner-text p:last-of-type {
        margin-bottom: 50px; }
    #webinar_retail_page .hero-section .banner-img, #webinar_tpo_page .hero-section .banner-img, #webinar_sp_page .hero-section .banner-img, #webinar_jv_page .hero-section .banner-img {
      margin: 0px -5px; } }
  @media only screen and (min-width: 768px) {
    #webinar_retail_page .hero-section .cmg-container, #webinar_tpo_page .hero-section .cmg-container, #webinar_sp_page .hero-section .cmg-container, #webinar_jv_page .hero-section .cmg-container {
      flex-direction: column; }
    #webinar_retail_page .hero-section .banner-text, #webinar_tpo_page .hero-section .banner-text, #webinar_sp_page .hero-section .banner-text, #webinar_jv_page .hero-section .banner-text {
      padding-top: 108px;
      padding-bottom: 0px; }
      #webinar_retail_page .hero-section .banner-text h2, #webinar_tpo_page .hero-section .banner-text h2, #webinar_sp_page .hero-section .banner-text h2, #webinar_jv_page .hero-section .banner-text h2 {
        margin-bottom: 34.96px;
        max-width: 457px; }
      #webinar_retail_page .hero-section .banner-text p, #webinar_tpo_page .hero-section .banner-text p, #webinar_sp_page .hero-section .banner-text p, #webinar_jv_page .hero-section .banner-text p {
        max-width: 457px; }
        #webinar_retail_page .hero-section .banner-text p:last-of-type, #webinar_tpo_page .hero-section .banner-text p:last-of-type, #webinar_sp_page .hero-section .banner-text p:last-of-type, #webinar_jv_page .hero-section .banner-text p:last-of-type {
          margin-bottom: 20px; }
    #webinar_retail_page .hero-section .banner-img, #webinar_tpo_page .hero-section .banner-img, #webinar_sp_page .hero-section .banner-img, #webinar_jv_page .hero-section .banner-img {
      padding-top: 0px; } }
  @media only screen and (min-width: 1024px) {
    #webinar_retail_page .hero-section .cmg-container, #webinar_tpo_page .hero-section .cmg-container, #webinar_sp_page .hero-section .cmg-container, #webinar_jv_page .hero-section .cmg-container {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 0px;
      padding-bottom: 0px; }
    #webinar_retail_page .hero-section .banner-text, #webinar_tpo_page .hero-section .banner-text, #webinar_sp_page .hero-section .banner-text, #webinar_jv_page .hero-section .banner-text {
      padding-top: 108px;
      padding-bottom: 108px;
      width: 470px; }
      #webinar_retail_page .hero-section .banner-text h1, #webinar_tpo_page .hero-section .banner-text h1, #webinar_sp_page .hero-section .banner-text h1, #webinar_jv_page .hero-section .banner-text h1 {
        max-width: 100%; }
      #webinar_retail_page .hero-section .banner-text h2, #webinar_tpo_page .hero-section .banner-text h2, #webinar_sp_page .hero-section .banner-text h2, #webinar_jv_page .hero-section .banner-text h2 {
        margin-bottom: 35px;
        max-width: 100%; }
      #webinar_retail_page .hero-section .banner-text p, #webinar_tpo_page .hero-section .banner-text p, #webinar_sp_page .hero-section .banner-text p, #webinar_jv_page .hero-section .banner-text p {
        max-width: 450px;
        max-width: 432px; }
        #webinar_retail_page .hero-section .banner-text p:last-of-type, #webinar_tpo_page .hero-section .banner-text p:last-of-type, #webinar_sp_page .hero-section .banner-text p:last-of-type, #webinar_jv_page .hero-section .banner-text p:last-of-type {
          margin-bottom: 0px; }
    #webinar_retail_page .hero-section .banner-img, #webinar_tpo_page .hero-section .banner-img, #webinar_sp_page .hero-section .banner-img, #webinar_jv_page .hero-section .banner-img {
      margin: unset;
      padding-top: 65px;
      margin-left: -50px; }
      #webinar_retail_page .hero-section .banner-img img, #webinar_tpo_page .hero-section .banner-img img, #webinar_sp_page .hero-section .banner-img img, #webinar_jv_page .hero-section .banner-img img {
        width: 480px;
        height: 345px; } }
  @media only screen and (min-width: 1200px) {
    #webinar_retail_page .hero-section .cmg-container, #webinar_tpo_page .hero-section .cmg-container, #webinar_sp_page .hero-section .cmg-container, #webinar_jv_page .hero-section .cmg-container {
      padding-top: 0px;
      padding-bottom: 0px; }
    #webinar_retail_page .hero-section .banner-text, #webinar_tpo_page .hero-section .banner-text, #webinar_sp_page .hero-section .banner-text, #webinar_jv_page .hero-section .banner-text {
      padding-top: 244px;
      padding-bottom: 116px;
      width: 660px; }
      #webinar_retail_page .hero-section .banner-text h1, #webinar_tpo_page .hero-section .banner-text h1, #webinar_sp_page .hero-section .banner-text h1, #webinar_jv_page .hero-section .banner-text h1 {
        font-size: 28px;
        max-width: 100%; }
      #webinar_retail_page .hero-section .banner-text h2, #webinar_tpo_page .hero-section .banner-text h2, #webinar_sp_page .hero-section .banner-text h2, #webinar_jv_page .hero-section .banner-text h2 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 35px;
        max-width: 100%; }
      #webinar_retail_page .hero-section .banner-text p, #webinar_tpo_page .hero-section .banner-text p, #webinar_sp_page .hero-section .banner-text p, #webinar_jv_page .hero-section .banner-text p {
        max-width: 100%; }
        #webinar_retail_page .hero-section .banner-text p:last-of-type, #webinar_tpo_page .hero-section .banner-text p:last-of-type, #webinar_sp_page .hero-section .banner-text p:last-of-type, #webinar_jv_page .hero-section .banner-text p:last-of-type {
          margin-bottom: 0px; }
    #webinar_retail_page .hero-section .banner-img, #webinar_tpo_page .hero-section .banner-img, #webinar_sp_page .hero-section .banner-img, #webinar_jv_page .hero-section .banner-img {
      padding-top: 151px; }
      #webinar_retail_page .hero-section .banner-img img, #webinar_tpo_page .hero-section .banner-img img, #webinar_sp_page .hero-section .banner-img img, #webinar_jv_page .hero-section .banner-img img {
        width: 590px;
        height: 403px; } }
  @media only screen and (min-width: 1440px) {
    #webinar_retail_page .hero-section .cmg-container, #webinar_tpo_page .hero-section .cmg-container, #webinar_sp_page .hero-section .cmg-container, #webinar_jv_page .hero-section .cmg-container {
      padding-top: 0px;
      padding-bottom: 0px; }
    #webinar_retail_page .hero-section .banner-text h1, #webinar_tpo_page .hero-section .banner-text h1, #webinar_sp_page .hero-section .banner-text h1, #webinar_jv_page .hero-section .banner-text h1 {
      font-size: 28px;
      line-height: 50px;
      margin-bottom: 0px; }
    #webinar_retail_page .hero-section .banner-text h2, #webinar_tpo_page .hero-section .banner-text h2, #webinar_sp_page .hero-section .banner-text h2, #webinar_jv_page .hero-section .banner-text h2 {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 29px; }
    #webinar_retail_page .hero-section .banner-text p, #webinar_tpo_page .hero-section .banner-text p, #webinar_sp_page .hero-section .banner-text p, #webinar_jv_page .hero-section .banner-text p {
      font-size: 16px;
      line-height: 24px; }
      #webinar_retail_page .hero-section .banner-text p:first-of-type, #webinar_tpo_page .hero-section .banner-text p:first-of-type, #webinar_sp_page .hero-section .banner-text p:first-of-type, #webinar_jv_page .hero-section .banner-text p:first-of-type {
        margin-bottom: 17px; }
    #webinar_retail_page .hero-section .banner-img img, #webinar_tpo_page .hero-section .banner-img img, #webinar_sp_page .hero-section .banner-img img, #webinar_jv_page .hero-section .banner-img img {
      width: 605px;
      height: 403px; } }
  @media only screen and (min-width: 1920px) {
    #webinar_retail_page .hero-section .cmg-container, #webinar_tpo_page .hero-section .cmg-container, #webinar_sp_page .hero-section .cmg-container, #webinar_jv_page .hero-section .cmg-container {
      padding-top: 0px;
      padding-bottom: 0px; }
    #webinar_retail_page .hero-section .banner-text, #webinar_tpo_page .hero-section .banner-text, #webinar_sp_page .hero-section .banner-text, #webinar_jv_page .hero-section .banner-text {
      padding-top: 319px;
      padding-bottom: 121px;
      width: 670px; }
      #webinar_retail_page .hero-section .banner-text h1, #webinar_tpo_page .hero-section .banner-text h1, #webinar_sp_page .hero-section .banner-text h1, #webinar_jv_page .hero-section .banner-text h1 {
        font-size: 40px; }
      #webinar_retail_page .hero-section .banner-text h2, #webinar_tpo_page .hero-section .banner-text h2, #webinar_sp_page .hero-section .banner-text h2, #webinar_jv_page .hero-section .banner-text h2 {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 29px; }
      #webinar_retail_page .hero-section .banner-text p, #webinar_tpo_page .hero-section .banner-text p, #webinar_sp_page .hero-section .banner-text p, #webinar_jv_page .hero-section .banner-text p {
        font-size: 16px;
        line-height: 24px;
        max-width: 628px; }
        #webinar_retail_page .hero-section .banner-text p:first-of-type, #webinar_tpo_page .hero-section .banner-text p:first-of-type, #webinar_sp_page .hero-section .banner-text p:first-of-type, #webinar_jv_page .hero-section .banner-text p:first-of-type {
          margin-bottom: 17px; }
    #webinar_retail_page .hero-section .banner-img, #webinar_tpo_page .hero-section .banner-img, #webinar_sp_page .hero-section .banner-img, #webinar_jv_page .hero-section .banner-img {
      padding-top: 194px; }
      #webinar_retail_page .hero-section .banner-img img, #webinar_tpo_page .hero-section .banner-img img, #webinar_sp_page .hero-section .banner-img img, #webinar_jv_page .hero-section .banner-img img {
        width: 100%;
        height: 466px; } }
  @media only screen and (min-width: 2560px) {
    #webinar_retail_page .hero-section .cmg-container, #webinar_tpo_page .hero-section .cmg-container, #webinar_sp_page .hero-section .cmg-container, #webinar_jv_page .hero-section .cmg-container {
      padding-top: 0px;
      padding-bottom: 0px; }
    #webinar_retail_page .hero-section .banner-text, #webinar_tpo_page .hero-section .banner-text, #webinar_sp_page .hero-section .banner-text, #webinar_jv_page .hero-section .banner-text {
      padding-top: 367px;
      padding-bottom: 121px; }
      #webinar_retail_page .hero-section .banner-text p, #webinar_tpo_page .hero-section .banner-text p, #webinar_sp_page .hero-section .banner-text p, #webinar_jv_page .hero-section .banner-text p {
        max-width: 628px; }
    #webinar_retail_page .hero-section .banner-img, #webinar_tpo_page .hero-section .banner-img, #webinar_sp_page .hero-section .banner-img, #webinar_jv_page .hero-section .banner-img {
      padding-top: 193px; }
      #webinar_retail_page .hero-section .banner-img img, #webinar_tpo_page .hero-section .banner-img img, #webinar_sp_page .hero-section .banner-img img, #webinar_jv_page .hero-section .banner-img img {
        width: 100%;
        height: 515px; } }

#webinar_retail_page .webinar-section, #webinar_jv_page .webinar-section, #webinar_sp_page .webinar-section, #webinar_tpo_page .webinar-section {
  padding-top: 32px;
  padding-bottom: 8px;
  background-color: #F7F7F7; }
  #webinar_retail_page .webinar-section .cmg-container, #webinar_jv_page .webinar-section .cmg-container, #webinar_sp_page .webinar-section .cmg-container, #webinar_tpo_page .webinar-section .cmg-container {
    display: flex;
    flex-direction: column; }
  #webinar_retail_page .webinar-section .button-cmg, #webinar_jv_page .webinar-section .button-cmg, #webinar_sp_page .webinar-section .button-cmg, #webinar_tpo_page .webinar-section .button-cmg {
    padding: 12px 33px; }
  #webinar_retail_page .webinar-section .one, #webinar_jv_page .webinar-section .one, #webinar_sp_page .webinar-section .one, #webinar_tpo_page .webinar-section .one {
    width: 100%; }
  #webinar_retail_page .webinar-section .two, #webinar_jv_page .webinar-section .two, #webinar_sp_page .webinar-section .two, #webinar_tpo_page .webinar-section .two {
    width: 100%; }
  #webinar_retail_page .webinar-section .three, #webinar_jv_page .webinar-section .three, #webinar_sp_page .webinar-section .three, #webinar_tpo_page .webinar-section .three {
    width: 100%; }
  #webinar_retail_page .webinar-section .item, #webinar_jv_page .webinar-section .item, #webinar_sp_page .webinar-section .item, #webinar_tpo_page .webinar-section .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    border: 1px solid #C9C9C9;
    border-radius: 5px;
    background-color: #FFFFFF; }
    #webinar_retail_page .webinar-section .item .webinar-head, #webinar_jv_page .webinar-section .item .webinar-head, #webinar_sp_page .webinar-section .item .webinar-head, #webinar_tpo_page .webinar-section .item .webinar-head {
      background-color: #F7F7F7;
      border-radius: 5px 5px 0px 0px;
      padding: 25px; }
      #webinar_retail_page .webinar-section .item .webinar-head .info-header, #webinar_jv_page .webinar-section .item .webinar-head .info-header, #webinar_sp_page .webinar-section .item .webinar-head .info-header, #webinar_tpo_page .webinar-section .item .webinar-head .info-header {
        display: flex;
        flex-direction: column;
        justify-content: center; }
      #webinar_retail_page .webinar-section .item .webinar-head .info, #webinar_jv_page .webinar-section .item .webinar-head .info, #webinar_sp_page .webinar-section .item .webinar-head .info, #webinar_tpo_page .webinar-section .item .webinar-head .info {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px; }
        #webinar_retail_page .webinar-section .item .webinar-head .info img, #webinar_jv_page .webinar-section .item .webinar-head .info img, #webinar_sp_page .webinar-section .item .webinar-head .info img, #webinar_tpo_page .webinar-section .item .webinar-head .info img {
          width: 18px;
          margin-right: 8px; }
        #webinar_retail_page .webinar-section .item .webinar-head .info p, #webinar_jv_page .webinar-section .item .webinar-head .info p, #webinar_sp_page .webinar-section .item .webinar-head .info p, #webinar_tpo_page .webinar-section .item .webinar-head .info p {
          margin-top: 0px; }
        #webinar_retail_page .webinar-section .item .webinar-head .info .marginBottom, #webinar_jv_page .webinar-section .item .webinar-head .info .marginBottom, #webinar_sp_page .webinar-section .item .webinar-head .info .marginBottom, #webinar_tpo_page .webinar-section .item .webinar-head .info .marginBottom {
          margin-bottom: 0px; }
        #webinar_retail_page .webinar-section .item .webinar-head .info:last-of-type, #webinar_jv_page .webinar-section .item .webinar-head .info:last-of-type, #webinar_sp_page .webinar-section .item .webinar-head .info:last-of-type, #webinar_tpo_page .webinar-section .item .webinar-head .info:last-of-type {
          margin-bottom: 0px; }
      #webinar_retail_page .webinar-section .item .webinar-head .div-button, #webinar_jv_page .webinar-section .item .webinar-head .div-button, #webinar_sp_page .webinar-section .item .webinar-head .div-button, #webinar_tpo_page .webinar-section .item .webinar-head .div-button {
        display: flex;
        align-items: center;
        margin-top: 20px; }
        #webinar_retail_page .webinar-section .item .webinar-head .div-button a, #webinar_jv_page .webinar-section .item .webinar-head .div-button a, #webinar_sp_page .webinar-section .item .webinar-head .div-button a, #webinar_tpo_page .webinar-section .item .webinar-head .div-button a {
          width: 100%; }
    #webinar_retail_page .webinar-section .item .webinar-body, #webinar_jv_page .webinar-section .item .webinar-body, #webinar_sp_page .webinar-section .item .webinar-body, #webinar_tpo_page .webinar-section .item .webinar-body {
      background-color: #FFFFFF;
      border-radius: 0px 0px 5px 5px;
      padding: 25px; }
      #webinar_retail_page .webinar-section .item .webinar-body h3, #webinar_jv_page .webinar-section .item .webinar-body h3, #webinar_sp_page .webinar-section .item .webinar-body h3, #webinar_tpo_page .webinar-section .item .webinar-body h3 {
        color: #175A80;
        font-family: 'Kadwa', serif;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0px;
        font-weight: 700; }
    #webinar_retail_page .webinar-section .item p, #webinar_jv_page .webinar-section .item p, #webinar_sp_page .webinar-section .item p, #webinar_tpo_page .webinar-section .item p {
      color: #4D4D4D;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      font-weight: normal;
      margin-bottom: 20px; }
      #webinar_retail_page .webinar-section .item p span, #webinar_jv_page .webinar-section .item p span, #webinar_sp_page .webinar-section .item p span, #webinar_tpo_page .webinar-section .item p span {
        font-weight: 700; }
      #webinar_retail_page .webinar-section .item p:last-of-type, #webinar_jv_page .webinar-section .item p:last-of-type, #webinar_sp_page .webinar-section .item p:last-of-type, #webinar_tpo_page .webinar-section .item p:last-of-type {
        margin-bottom: 0px; }
  @media only screen and (min-width: 480px) {
    #webinar_retail_page .webinar-section, #webinar_jv_page .webinar-section, #webinar_sp_page .webinar-section, #webinar_tpo_page .webinar-section {
      padding-top: 60px;
      padding-bottom: 35px; }
      #webinar_retail_page .webinar-section .item .webinar-head, #webinar_jv_page .webinar-section .item .webinar-head, #webinar_sp_page .webinar-section .item .webinar-head, #webinar_tpo_page .webinar-section .item .webinar-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 40px; }
        #webinar_retail_page .webinar-section .item .webinar-head .info, #webinar_jv_page .webinar-section .item .webinar-head .info, #webinar_sp_page .webinar-section .item .webinar-head .info, #webinar_tpo_page .webinar-section .item .webinar-head .info {
          /* .marginBottom {
                            margin-bottom: 10px;
                        }*/ }
          #webinar_retail_page .webinar-section .item .webinar-head .info img, #webinar_jv_page .webinar-section .item .webinar-head .info img, #webinar_sp_page .webinar-section .item .webinar-head .info img, #webinar_tpo_page .webinar-section .item .webinar-head .info img {
            margin-right: 8px; }
        #webinar_retail_page .webinar-section .item .webinar-head .div-button, #webinar_jv_page .webinar-section .item .webinar-head .div-button, #webinar_sp_page .webinar-section .item .webinar-head .div-button, #webinar_tpo_page .webinar-section .item .webinar-head .div-button {
          margin-top: 0px; }
          #webinar_retail_page .webinar-section .item .webinar-head .div-button a, #webinar_jv_page .webinar-section .item .webinar-head .div-button a, #webinar_sp_page .webinar-section .item .webinar-head .div-button a, #webinar_tpo_page .webinar-section .item .webinar-head .div-button a {
            width: 130px; }
      #webinar_retail_page .webinar-section .item .webinar-body, #webinar_jv_page .webinar-section .item .webinar-body, #webinar_sp_page .webinar-section .item .webinar-body, #webinar_tpo_page .webinar-section .item .webinar-body {
        padding: 40px; } }
  @media only screen and (min-width: 768px) {
    #webinar_retail_page .webinar-section, #webinar_jv_page .webinar-section, #webinar_sp_page .webinar-section, #webinar_tpo_page .webinar-section {
      padding-top: 85px;
      padding-bottom: 60px; }
      #webinar_retail_page .webinar-section .item .webinar-head, #webinar_jv_page .webinar-section .item .webinar-head, #webinar_sp_page .webinar-section .item .webinar-head, #webinar_tpo_page .webinar-section .item .webinar-head {
        display: flex;
        flex-direction: row;
        padding: 15px 40px; }
        #webinar_retail_page .webinar-section .item .webinar-head .info-header, #webinar_jv_page .webinar-section .item .webinar-head .info-header, #webinar_sp_page .webinar-section .item .webinar-head .info-header, #webinar_tpo_page .webinar-section .item .webinar-head .info-header {
          flex-direction: column;
          align-items: flex-start; }
        #webinar_retail_page .webinar-section .item .webinar-head .info, #webinar_jv_page .webinar-section .item .webinar-head .info, #webinar_sp_page .webinar-section .item .webinar-head .info, #webinar_tpo_page .webinar-section .item .webinar-head .info {
          margin-right: 24px; }
          #webinar_retail_page .webinar-section .item .webinar-head .info p, #webinar_jv_page .webinar-section .item .webinar-head .info p, #webinar_sp_page .webinar-section .item .webinar-head .info p, #webinar_tpo_page .webinar-section .item .webinar-head .info p {
            margin-bottom: 0px; }
          #webinar_retail_page .webinar-section .item .webinar-head .info .marginBottom, #webinar_jv_page .webinar-section .item .webinar-head .info .marginBottom, #webinar_sp_page .webinar-section .item .webinar-head .info .marginBottom, #webinar_tpo_page .webinar-section .item .webinar-head .info .marginBottom {
            margin-bottom: 0px; }
        #webinar_retail_page .webinar-section .item .webinar-head .div-button, #webinar_jv_page .webinar-section .item .webinar-head .div-button, #webinar_sp_page .webinar-section .item .webinar-head .div-button, #webinar_tpo_page .webinar-section .item .webinar-head .div-button {
          margin-top: 0px; }
      #webinar_retail_page .webinar-section .item .webinar-body, #webinar_jv_page .webinar-section .item .webinar-body, #webinar_sp_page .webinar-section .item .webinar-body, #webinar_tpo_page .webinar-section .item .webinar-body {
        padding: 40px; } }
  @media only screen and (min-width: 1024px) {
    #webinar_retail_page .webinar-section .cmg-container, #webinar_jv_page .webinar-section .cmg-container, #webinar_sp_page .webinar-section .cmg-container, #webinar_tpo_page .webinar-section .cmg-container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; }
    #webinar_retail_page .webinar-section .one, #webinar_jv_page .webinar-section .one, #webinar_sp_page .webinar-section .one, #webinar_tpo_page .webinar-section .one {
      width: 49%; }
    #webinar_retail_page .webinar-section .two, #webinar_jv_page .webinar-section .two, #webinar_sp_page .webinar-section .two, #webinar_tpo_page .webinar-section .two {
      width: 49%; }
    #webinar_retail_page .webinar-section .three, #webinar_jv_page .webinar-section .three, #webinar_sp_page .webinar-section .three, #webinar_tpo_page .webinar-section .three {
      width: 32%; }
    #webinar_retail_page .webinar-section .item .webinar-head, #webinar_jv_page .webinar-section .item .webinar-head, #webinar_sp_page .webinar-section .item .webinar-head, #webinar_tpo_page .webinar-section .item .webinar-head {
      display: flex;
      flex-direction: row; }
      #webinar_retail_page .webinar-section .item .webinar-head .info-header, #webinar_jv_page .webinar-section .item .webinar-head .info-header, #webinar_sp_page .webinar-section .item .webinar-head .info-header, #webinar_tpo_page .webinar-section .item .webinar-head .info-header {
        flex-direction: column; }
      #webinar_retail_page .webinar-section .item .webinar-head .info p, #webinar_jv_page .webinar-section .item .webinar-head .info p, #webinar_sp_page .webinar-section .item .webinar-head .info p, #webinar_tpo_page .webinar-section .item .webinar-head .info p {
        margin-bottom: 0px; }
      #webinar_retail_page .webinar-section .item .webinar-head .div-button, #webinar_jv_page .webinar-section .item .webinar-head .div-button, #webinar_sp_page .webinar-section .item .webinar-head .div-button, #webinar_tpo_page .webinar-section .item .webinar-head .div-button {
        margin-top: 0px; }
    #webinar_retail_page .webinar-section .item .webinar-body, #webinar_jv_page .webinar-section .item .webinar-body, #webinar_sp_page .webinar-section .item .webinar-body, #webinar_tpo_page .webinar-section .item .webinar-body {
      padding: 40px; } }
  @media only screen and (min-width: 1280px) {
    #webinar_retail_page .webinar-section, #webinar_jv_page .webinar-section, #webinar_sp_page .webinar-section, #webinar_tpo_page .webinar-section {
      padding-top: 85px;
      padding-bottom: 60px; }
      #webinar_retail_page .webinar-section .item .webinar-head .info p, #webinar_jv_page .webinar-section .item .webinar-head .info p, #webinar_sp_page .webinar-section .item .webinar-head .info p, #webinar_tpo_page .webinar-section .item .webinar-head .info p {
        margin-bottom: 0px; }
      #webinar_retail_page .webinar-section .item .webinar-head .div-button, #webinar_jv_page .webinar-section .item .webinar-head .div-button, #webinar_sp_page .webinar-section .item .webinar-head .div-button, #webinar_tpo_page .webinar-section .item .webinar-head .div-button {
        margin-top: 0px;
        margin-left: 0px; }
      #webinar_retail_page .webinar-section .item .webinar-body, #webinar_jv_page .webinar-section .item .webinar-body, #webinar_sp_page .webinar-section .item .webinar-body, #webinar_tpo_page .webinar-section .item .webinar-body {
        padding: 40px;
        height: auto; } }
  @media only screen and (min-width: 1440px) {
    #webinar_retail_page .webinar-section .cmg-container, #webinar_jv_page .webinar-section .cmg-container, #webinar_sp_page .webinar-section .cmg-container, #webinar_tpo_page .webinar-section .cmg-container {
      flex-direction: row;
      flex-wrap: wrap; }
    #webinar_retail_page .webinar-section .item .webinar-head .info p, #webinar_jv_page .webinar-section .item .webinar-head .info p, #webinar_sp_page .webinar-section .item .webinar-head .info p, #webinar_tpo_page .webinar-section .item .webinar-head .info p {
      margin-bottom: 0px; }
    #webinar_retail_page .webinar-section .item .webinar-head .div-button, #webinar_jv_page .webinar-section .item .webinar-head .div-button, #webinar_sp_page .webinar-section .item .webinar-head .div-button, #webinar_tpo_page .webinar-section .item .webinar-head .div-button {
      margin-top: 0px; }
    #webinar_retail_page .webinar-section .item .webinar-body, #webinar_jv_page .webinar-section .item .webinar-body, #webinar_sp_page .webinar-section .item .webinar-body, #webinar_tpo_page .webinar-section .item .webinar-body {
      padding: 40px;
      height: auto; } }
  @media only screen and (min-width: 1920px) {
    #webinar_retail_page .webinar-section .cmg-container, #webinar_jv_page .webinar-section .cmg-container, #webinar_sp_page .webinar-section .cmg-container, #webinar_tpo_page .webinar-section .cmg-container {
      flex-direction: row; }
    #webinar_retail_page .webinar-section .item .webinar-head .info p, #webinar_jv_page .webinar-section .item .webinar-head .info p, #webinar_sp_page .webinar-section .item .webinar-head .info p, #webinar_tpo_page .webinar-section .item .webinar-head .info p {
      margin-bottom: 0px; }
    #webinar_retail_page .webinar-section .item .webinar-head .div-button, #webinar_jv_page .webinar-section .item .webinar-head .div-button, #webinar_sp_page .webinar-section .item .webinar-head .div-button, #webinar_tpo_page .webinar-section .item .webinar-head .div-button {
      margin-top: 0px; }
    #webinar_retail_page .webinar-section .item .webinar-body, #webinar_jv_page .webinar-section .item .webinar-body, #webinar_sp_page .webinar-section .item .webinar-body, #webinar_tpo_page .webinar-section .item .webinar-body {
      padding: 40px;
      height: auto; }
    #webinar_retail_page .webinar-section .item p, #webinar_jv_page .webinar-section .item p, #webinar_sp_page .webinar-section .item p, #webinar_tpo_page .webinar-section .item p {
      font-size: 16px;
      line-height: 24px; } }
  @media only screen and (min-width: 2560px) {
    #webinar_retail_page .webinar-section .cmg-container, #webinar_jv_page .webinar-section .cmg-container, #webinar_sp_page .webinar-section .cmg-container, #webinar_tpo_page .webinar-section .cmg-container {
      flex-direction: row; }
    #webinar_retail_page .webinar-section .item .webinar-head .info p, #webinar_jv_page .webinar-section .item .webinar-head .info p, #webinar_sp_page .webinar-section .item .webinar-head .info p, #webinar_tpo_page .webinar-section .item .webinar-head .info p {
      margin-bottom: 0px; }
    #webinar_retail_page .webinar-section .item .webinar-head .div-button, #webinar_jv_page .webinar-section .item .webinar-head .div-button, #webinar_sp_page .webinar-section .item .webinar-head .div-button, #webinar_tpo_page .webinar-section .item .webinar-head .div-button {
      margin-top: 0px; }
    #webinar_retail_page .webinar-section .item .webinar-body, #webinar_jv_page .webinar-section .item .webinar-body, #webinar_sp_page .webinar-section .item .webinar-body, #webinar_tpo_page .webinar-section .item .webinar-body {
      padding: 40px;
      height: auto; } }

#webinar_retail_page .disclaimer-section {
  background-color: #F7F7F7; }

#webinar_ll_page .hero-section {
  background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
  background-position: 10% center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  #webinar_ll_page .hero-section .cmg-container {
    display: flex;
    flex-direction: column;
    padding-top: 82px;
    padding-bottom: 66px; }
  #webinar_ll_page .hero-section .banner-text h1 {
    font-family: 'Kadwa', serif;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0px;
    font-weight: 700;
    color: #175A80;
    margin-bottom: 8px; }
  #webinar_ll_page .hero-section .banner-text h2 {
    font-size: 16px;
    color: #4D4D4D;
    font-weight: normal;
    margin-bottom: 28px;
    line-height: 24px; }
  #webinar_ll_page .hero-section .banner-text p {
    font-family: 'Open Sans', sans-serif;
    color: #4D4D4D;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    max-width: 100%; }
    #webinar_ll_page .hero-section .banner-text p span {
      font-weight: 700;
      font-size: 14px; }
    #webinar_ll_page .hero-section .banner-text p:first-of-type {
      margin-bottom: 20px; }
    #webinar_ll_page .hero-section .banner-text p:last-of-type {
      margin-bottom: 36px; }
  #webinar_ll_page .hero-section .banner-img {
    padding-top: 0px; }
    #webinar_ll_page .hero-section .banner-img .video-card {
      width: 100%; }
      #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] {
        flex: 0 0 100%;
        height: 193px;
        overflow: hidden;
        z-index: 2;
        box-shadow: 0px 8px 20px #00000029;
        border-radius: 5px;
        position: relative; }
      #webinar_ll_page .hero-section .banner-img .video-card [data-role="title"] {
        display: none; }
      #webinar_ll_page .hero-section .banner-img .video-card [data-role="play-button"] img {
        width: 50px;
        height: 50px; }
  @media only screen and (min-width: 425px) {
    #webinar_ll_page .hero-section {
      background-position: 0 center; } }
  @media only screen and (min-width: 480px) {
    #webinar_ll_page .hero-section .cmg-container {
      padding-top: 110px;
      padding-bottom: 90px; }
    #webinar_ll_page .hero-section .banner-text h1 {
      font-size: 28px;
      line-height: 40px; }
    #webinar_ll_page .hero-section .banner-text h2 {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 28px; }
    #webinar_ll_page .hero-section .banner-text p:last-of-type {
      margin-bottom: 48px; }
    #webinar_ll_page .hero-section .banner-img .video-card {
      width: 416px; }
      #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] {
        flex: initial;
        height: 271px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] [data-role="thumbnail"] {
          width: 416px;
          height: 271px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] [data-role="play-button"] img {
          width: 70px;
          height: 70px; } }
  @media only screen and (min-width: 768px) {
    #webinar_ll_page .hero-section .cmg-container {
      flex-direction: column;
      padding-top: 108px;
      padding-bottom: 90.77px; }
    #webinar_ll_page .hero-section .banner-text h2 {
      margin-bottom: 27.95px;
      max-width: 457px; }
    #webinar_ll_page .hero-section .banner-text p {
      max-width: 457px; }
    #webinar_ll_page .hero-section .banner-img .video-card {
      width: 648px; }
      #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] {
        flex: initial;
        height: 364px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] [data-role="thumbnail"] {
          width: 648px;
          height: 364px; } }
  @media only screen and (min-width: 1024px) {
    #webinar_ll_page .hero-section .cmg-container {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 0px;
      padding-bottom: 0px; }
    #webinar_ll_page .hero-section .banner-text {
      padding-top: 151px;
      padding-bottom: 139.95px;
      width: 432px;
      max-width: unset; }
      #webinar_ll_page .hero-section .banner-text h1 {
        line-height: 38px; }
      #webinar_ll_page .hero-section .banner-text h2 {
        margin-bottom: 28px; }
      #webinar_ll_page .hero-section .banner-text p {
        max-width: 100%; }
        #webinar_ll_page .hero-section .banner-text p:last-of-type {
          margin-bottom: 0px; }
    #webinar_ll_page .hero-section .banner-img {
      padding-top: 165px; }
      #webinar_ll_page .hero-section .banner-img .video-card {
        width: 432px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] {
          flex: initial;
          height: 243px; }
          #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] [data-role="thumbnail"] {
            width: 432px;
            height: 243px; }
          #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] [data-role="play-button"] {
            width: 71px;
            height: 71px; } }
  @media only screen and (min-width: 1200px) {
    #webinar_ll_page .hero-section .banner-text {
      padding-top: 244px;
      padding-bottom: 118px;
      width: 432px; }
      #webinar_ll_page .hero-section .banner-text h1 {
        font-size: 28px;
        line-height: 38px; }
      #webinar_ll_page .hero-section .banner-text h2 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 27.95px;
        max-width: 500px; }
      #webinar_ll_page .hero-section .banner-text p:last-of-type {
        margin-bottom: 0px; }
    #webinar_ll_page .hero-section .banner-img {
      padding-top: 244px; }
      #webinar_ll_page .hero-section .banner-img .video-card {
        width: 516px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] {
          flex: initial;
          height: 290px; }
          #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] [data-role="thumbnail"] {
            width: 516px;
            height: 290px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="title"] {
          display: block;
          font-family: 'Open Sans', sans-serif;
          color: #4D4D4D;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          margin-top: 19.96px;
          max-width: 100%;
          padding-left: 0px; } }
  @media only screen and (min-width: 1440px) {
    #webinar_ll_page .hero-section .banner-text {
      width: 516px; }
      #webinar_ll_page .hero-section .banner-text h1 {
        font-size: 28px;
        line-height: 50px;
        margin-bottom: 0px; }
      #webinar_ll_page .hero-section .banner-text h2 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 29px; }
      #webinar_ll_page .hero-section .banner-text p {
        font-size: 14px;
        line-height: 22px; }
        #webinar_ll_page .hero-section .banner-text p:first-of-type {
          margin-bottom: 17px; } }
  @media only screen and (min-width: 1920px) {
    #webinar_ll_page .hero-section .banner-text {
      padding-top: 319px;
      padding-bottom: 142px;
      width: 628px; }
      #webinar_ll_page .hero-section .banner-text h1 {
        font-size: 39px; }
      #webinar_ll_page .hero-section .banner-text h2 {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 29px; }
      #webinar_ll_page .hero-section .banner-text p {
        font-size: 16px;
        line-height: 24px; }
        #webinar_ll_page .hero-section .banner-text p:first-of-type {
          margin-bottom: 17px; }
    #webinar_ll_page .hero-section .banner-img {
      padding-top: 319px; }
      #webinar_ll_page .hero-section .banner-img .video-card {
        width: 628px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] {
          flex: initial;
          height: 353px; }
          #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] [data-role="thumbnail"] {
            width: 628px;
            height: 353px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="title"] {
          font-size: 16px;
          line-height: 24px; } }
  @media only screen and (min-width: 2560px) {
    #webinar_ll_page .hero-section .banner-text {
      padding-top: 367px;
      padding-bottom: 140px;
      max-width: 700px; }
      #webinar_ll_page .hero-section .banner-text h1 {
        font-size: 39px; }
      #webinar_ll_page .hero-section .banner-text h2 {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 31.95px; }
      #webinar_ll_page .hero-section .banner-text p {
        font-size: 16px;
        line-height: 24px; }
        #webinar_ll_page .hero-section .banner-text p:first-of-type {
          margin-bottom: 17px; }
    #webinar_ll_page .hero-section .banner-img {
      padding-top: 367px; }
      #webinar_ll_page .hero-section .banner-img .video-card {
        width: 700px; }
        #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] {
          flex: initial;
          height: 393px; }
          #webinar_ll_page .hero-section .banner-img .video-card [data-role="video-player"] [data-role="thumbnail"] {
            width: 700px;
            height: 393px; } }

#webinar_ll_page .webinar-section {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #F7F7F7; }
  #webinar_ll_page .webinar-section .cmg-container {
    display: flex;
    flex-direction: column; }
  #webinar_ll_page .webinar-section .one {
    width: 100%; }
  #webinar_ll_page .webinar-section .two {
    width: 100%; }
  #webinar_ll_page .webinar-section .three {
    width: 100%; }
  #webinar_ll_page .webinar-section .button-cmg {
    padding: 12px 33px; }
  #webinar_ll_page .webinar-section .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    #webinar_ll_page .webinar-section .item .webinar-head {
      display: flex;
      align-items: center;
      background: #F7F7F7 0% 0% no-repeat padding-box;
      border: 1px solid #C9C9C9;
      border-bottom: 0px solid #C9C9C9;
      border-radius: 5px 5px 0px 0px;
      padding: 0px 0px 0px 30px;
      height: 49px; }
      #webinar_ll_page .webinar-section .item .webinar-head .info-header {
        display: flex;
        flex-direction: column; }
    #webinar_ll_page .webinar-section .item .info {
      display: flex;
      flex-direction: row; }
      #webinar_ll_page .webinar-section .item .info img {
        width: 18px;
        margin-right: 8px; }
      #webinar_ll_page .webinar-section .item .info p {
        margin-top: 0px; }
    #webinar_ll_page .webinar-section .item .div-button {
      margin-top: 20px; }
      #webinar_ll_page .webinar-section .item .div-button a {
        width: 100%; }
    #webinar_ll_page .webinar-section .item .webinar-body {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #C9C9C9;
      border-radius: 0px 0px 5px 5px;
      padding: 30px; }
    #webinar_ll_page .webinar-section .item p {
      color: #4D4D4D;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      font-weight: normal;
      margin-bottom: 0px; }
      #webinar_ll_page .webinar-section .item p span {
        font-weight: 700; }
    #webinar_ll_page .webinar-section .item:last-of-type {
      margin-bottom: 0px; }
  @media only screen and (min-width: 480px) {
    #webinar_ll_page .webinar-section {
      padding-top: 60px;
      padding-bottom: 60px; }
      #webinar_ll_page .webinar-section .item .webinar-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0px 0px 30px;
        height: 62px; }
      #webinar_ll_page .webinar-section .item .info img {
        margin-right: 8px; }
      #webinar_ll_page .webinar-section .item .div-button {
        margin-top: 0px;
        margin-left: 0px; }
        #webinar_ll_page .webinar-section .item .div-button a {
          width: 130px; }
      #webinar_ll_page .webinar-section .item .webinar-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px; } }
  @media only screen and (min-width: 768px) {
    #webinar_ll_page .webinar-section {
      padding-top: 85px;
      padding-bottom: 85px; }
      #webinar_ll_page .webinar-section .item .webinar-head {
        display: flex;
        flex-direction: row;
        padding: 0px 0px 0px 30px; }
        #webinar_ll_page .webinar-section .item .webinar-head .info-header {
          flex-direction: row;
          align-items: center; }
      #webinar_ll_page .webinar-section .item .info {
        margin-right: 24px; }
        #webinar_ll_page .webinar-section .item .info p {
          margin-bottom: 0px; }
        #webinar_ll_page .webinar-section .item .info .marginBottom {
          margin-bottom: 0px; }
      #webinar_ll_page .webinar-section .item .div-button {
        margin-top: 0px; } }
  @media only screen and (min-width: 1024px) {
    #webinar_ll_page .webinar-section .cmg-container {
      flex-direction: row;
      justify-content: space-between; }
    #webinar_ll_page .webinar-section .one {
      width: 49%; }
    #webinar_ll_page .webinar-section .two {
      width: 49%; }
    #webinar_ll_page .webinar-section .three {
      width: 32%; }
    #webinar_ll_page .webinar-section .item {
      margin-bottom: 0px; }
      #webinar_ll_page .webinar-section .item .webinar-head {
        display: flex;
        flex-direction: row;
        height: 49px; }
        #webinar_ll_page .webinar-section .item .webinar-head .info-header {
          flex-direction: row; }
      #webinar_ll_page .webinar-section .item .info p {
        margin-bottom: 0px; }
      #webinar_ll_page .webinar-section .item .div-button {
        margin-top: 20px;
        width: 100%; }
        #webinar_ll_page .webinar-section .item .div-button a {
          width: 100%; }
      #webinar_ll_page .webinar-section .item .webinar-body {
        flex-direction: column;
        align-items: flex-start;
        padding: 30px; } }
  @media only screen and (min-width: 1280px) {
    #webinar_ll_page .webinar-section {
      padding-top: 85px;
      padding-bottom: 85px; }
      #webinar_ll_page .webinar-section .item {
        margin-bottom: 0px; }
        #webinar_ll_page .webinar-section .item .webinar-head {
          height: 62px;
          justify-content: space-between; }
        #webinar_ll_page .webinar-section .item .info {
          margin-right: 0px; }
          #webinar_ll_page .webinar-section .item .info p {
            margin-bottom: 0px; }
        #webinar_ll_page .webinar-section .item .div-button {
          margin-top: 0px;
          width: unset; }
          #webinar_ll_page .webinar-section .item .div-button a {
            width: 130px; }
        #webinar_ll_page .webinar-section .item .webinar-body {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 15px 30px; } }
  @media only screen and (min-width: 1440px) {
    #webinar_ll_page .webinar-section {
      padding-top: 85px;
      padding-bottom: 85px; }
      #webinar_ll_page .webinar-section .item {
        margin-bottom: 0px; }
        #webinar_ll_page .webinar-section .item .webinar-head {
          justify-content: space-between; }
        #webinar_ll_page .webinar-section .item .info p {
          margin-bottom: 0px; }
        #webinar_ll_page .webinar-section .item .div-button {
          margin-top: 0px; } }
  @media only screen and (min-width: 1920px) {
    #webinar_ll_page .webinar-section {
      padding-top: 85px;
      padding-bottom: 85px; }
      #webinar_ll_page .webinar-section .item {
        margin-bottom: 0px; }
        #webinar_ll_page .webinar-section .item .webinar-head {
          justify-content: space-between; }
        #webinar_ll_page .webinar-section .item .info p {
          margin-bottom: 0px; }
        #webinar_ll_page .webinar-section .item .div-button {
          margin-top: 0px; }
        #webinar_ll_page .webinar-section .item p {
          font-size: 16px;
          line-height: 24px; } }
  @media only screen and (min-width: 2560px) {
    #webinar_ll_page .webinar-section {
      padding-top: 85px;
      padding-bottom: 85px; }
      #webinar_ll_page .webinar-section .item {
        margin-bottom: 0px;
        margin-right: 30px; }
        #webinar_ll_page .webinar-section .item .webinar-head {
          justify-content: space-between; }
        #webinar_ll_page .webinar-section .item .info p {
          margin-bottom: 0px; }
        #webinar_ll_page .webinar-section .item .div-button {
          margin-top: 0px; }
        #webinar_ll_page .webinar-section .item p {
          font-size: 16px;
          line-height: 24px; } }
