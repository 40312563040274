﻿$aspect-ratio-ns: aspect-ratio;

.#{$aspect-ratio-ns} {
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 100%;
    }

    &.#{$aspect-ratio-ns}--16-9 {

        &:before {
            padding-top: (9  / 16) * 100%;
        }
    }

    &.#{$aspect-ratio-ns}--25-19 {

        &:before {
            padding-top: (19.3  / 25) * 100%;
        }
    }

    &.#{$aspect-ratio-ns}--4-3 {

        &:before {
            padding-top: (3 / 4) * 100%;
        }
    }

    &.#{$aspect-ratio-ns}--3-2 {

        &:before {
            padding-top: (2 / 3) * 100%;
        }
    }
}
