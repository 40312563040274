﻿#contact_us_p_page, #contact_us_b_page, #partner_name_page {

    .hero-section {
        background-image: url('https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1605641201/AIOWebsite/Shared_Images/interior-bg.png');
        height: auto;
        background-size: cover;
        display: flex;
        //justify-content: center;
        flex-direction: column;

        .cmg-container {
            flex-direction: column;
            padding-top: 65px;
            padding-bottom: 62px;
        }

        .contents {
            display: flex;
            justify-content: left;

            h1 {
                font-family: 'Kadwa', serif;
                font-weight: 700;
                font-size: 16px;
                line-height: 34px;
                margin-bottom: 14px;
                color: #175A80;
            }
        }

        .contactIframe {
            width: 100%;
            height: 510px;
        }

        .div-iframe {
            justify-content: center;
            align-items: center;
            text-align: center;

            iframe {
                border-width: 0px;
                background: #5F5F5F 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
            }
        }

        iframe {
            border-width: 0px;
            background: #5F5F5F 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
        }

        @media only screen and (min-width: 480px) {

            .cmg-container {
                padding-top: 108px;
            }

            .contents {

                h1 {
                    font-size: 18px;
                    // line-height: 60px;
                }
            }
        }

        @media only screen and (min-width: 768px) {

            .cmg-container {
                padding-top: 95px;
                padding-bottom: 115px;
            }

            .contents {

                h1 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 19px;
                }
            }
        }


        @media only screen and (min-width: 1024px) {

            .cmg-container {
                padding-top: 95px;
                padding-bottom: 95px;
            }

            .contents {

                h1 {
                }
            }
        }

        @media only screen and (min-width: 1200px) {

            .cmg-container {
                padding-top: 206px;
                padding-bottom: 134px;
                padding-left: 161px;
                padding-right: 160px;
            }

            .contents {
            }

            .div-iframe {
                margin: 0px auto;
                width: 959px;
                //: 75px 300px;
            }
        }

        @media only screen and (min-width: 1440px) {

            .cmg-container {
                padding-left: 241px;
                padding-right: 240px;
            }
        }

        @media only screen and (min-width: 1920px) {

            .cmg-container {
                padding-top: 206px;
                padding-bottom: 103px;
                padding-left: 480px;
                padding-right: 480px;
            }

            .contents {
                h1 {
                    font-size: 20px;
                    line-height: 28px;
                    margin-bottom: 17px;
                }
            }
        }

        @media only screen and (min-width: 2560px) {

            .cmg-container {
                padding-top: 206px;
                padding-bottom: 131px;
                padding-left: 801px;
                padding-right: 800px;
            }

            .contactIframe {
                height: 600px;
            }
        }
    }
}
