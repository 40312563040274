[data-role="video-player"] {
    //height: 100%;
    [data-role="thumbnail"] {
        position: relative;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;

        &.thumbnail-center {
            background-position: center;
        }
    }

    [data-role="play-button"] {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

}
