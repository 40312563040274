﻿@import '../shared/palette';
@import '../shared/_mixins';

#primary_second_page {

    .hero-section {
        background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
        background-position: 25% center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;


        .cmg-container {
            display: flex;
            flex-direction: column-reverse;
            overflow-x: hidden;
        }


        .banner-text {
            padding-top: 18px;
            padding-bottom: 61.7px;

            h1 {
                font-family: 'Kadwa', serif;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0px;
                font-weight: 700;
                color: #175A80;
                margin-bottom: 0px;
            }

            h2 {
                font-size: 16px;
                color: #4D4D4D;
                font-weight: normal;
                margin-bottom: 40px;
                line-height: 24px;
                padding-top: 16px;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }

            .list-items {
                ul {
                    list-style-type: none;
                    list-style-position: outside;
                    margin-inline-start: 0px;
                    padding-inline-start: 0px;
                    margin-bottom: 0px;
                    // list-style-image: url(https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1608734277/AIOWebsite/Partners_Images/check.svg);
                }

                ul li {
                    display: flex;
                    margin-bottom: 19px;

                    span {
                        margin-top: -5px;
                        font-family: 'Open Sans', sans-serif;
                        color: #4D4D4D;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        padding-left: 13.23px;
                    }

                    img {
                        width: 14.15px;
                        height: 13.59px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .banner-img {
            padding-top: 0px;
            margin: 0px -32px;

            img {
                width: 100%;
            }

            #img-mob {
                display: block;
            }

            #img-web {
                display: none;
            }
        }

        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
            background-position: 30% center;
        }

        @media only screen and (min-width: 480px) {
            .cmg-container {
            }

            .banner-text {
                padding-top: 24px;
                padding-bottom: 73px;

                h1 {
                    font-size: 28px;
                    line-height: 46px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 43px;
                    padding-top: 0px;
                }

                p {

                    &:first-of-type {
                        // margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-bottom: 43px;
                    }
                }
            }

            .banner-img {
                margin: 0px -60px;
            }
        }

        @media only screen and (min-width: 768px) {
         //   background-position: 80px center;
         background-attachment: fixed;
            background-position: top;

            .cmg-container {
                flex-direction: column;
            }

            .banner-text {
                padding-top: 72px;
                padding-bottom: 70px;

                h2 {
                    margin-bottom: 34.96px;
                    max-width: 457px;
                }

                p {
                    max-width: 457px;

                    &:last-of-type {
                        margin-bottom: 42px;
                    }
                }
            }

            .banner-img {
                padding-top: 0px;

                #img-mob {
                    display: none;
                }

                #img-web {
                    display: block;
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
            background-position: 30% center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            background-attachment:unset;

            .cmg-container {
                flex-direction: row;
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .banner-text {
                background-image: none;
                padding-top: 72px;
                padding-bottom: 56px;
                width: 453px;
                margin: unset;

                h2 {
                    margin-bottom: 35px;
                }

                p {
                    max-width: 450px;
                }

                .list-items {
                    margin-right: -10px;
                }
            }

            .banner-img {
                padding-top: 157px;
                position: relative;
                margin: unset;
                // margin-right: -100px;
                img {
                    width: auto;
                    height: 396px;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                }
            }
        }

        @media only screen and (min-width: 1200px) {
            // background-position: 60px center;
            .cmg-container {
                padding-top: 0px;
                padding-bottom: 0px;
            }


            .banner-text {
                padding-top: 119px;
                padding-bottom: 119.7px;

                h1 {
                    font-size: 28px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 35px;
                }

                p {
                    &:last-of-type {
                        margin-bottom: 42px;
                    }
                }

                .list-items {
                    ul li {
                        span {
                            padding-left: 14.44px;
                        }
                    }
                }
            }

            .banner-img {
                margin-right: unset;

                img {
                    width: auto;
                    height: 605px;
                    left: -5px;
                }
            }
        }

        @media only screen and (min-width: 1440px) {
            //background-position: 60px center;
            .cmg-container {
            }


            .banner-text {
                padding-top: 107px;
                padding-bottom: 107px;
                width: 518px;

                h1 {
                    font-size: 40px;
                    line-height: 50px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 29px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 516px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 38px;
                    }
                }

                .list-items {
                    ul {
                    }

                    ul li {
                        img {
                            width: 17.7px;
                            height: 17.07px;
                        }

                        span {
                            margin-top: -4px;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .banner-img {
                img {
                    // width: 816px;
                    height: 605px;
                    left: -30px;
                }
            }
        }

        @media only screen and (min-width: 1920px) {
            //  background-position: 60px center;
            .cmg-container {
            }


            .banner-text {
                padding-top: 107px;
                padding-bottom: 107px;

                h1 {
                    font-size: 40px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 29px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 38px;
                    }
                }

                .list-items {

                    ul li {

                        span {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .banner-img {
                img {
                    width: 1013px;
                    height: 723px;
                    left: 60px;
                }
            }
        }

        @media only screen and (min-width: 2560px) {
            .banner-text {
                padding-top: 234px;
                padding-bottom: 233px;
            }

            .banner-img {
                img {
                    width: 1192px;
                    height: 851px;
                    left: 0px;
                }
            }
        }
    }
}
