﻿@import './_mixins';

#page_404,
#error_page {
    padding-top: 100px;
    padding-bottom: 80px;

    @include wrapper(1000px, true);

    h6 {
        margin-bottom: 30px;
    }

    @media only screen and (min-width: 1200px) {
        padding-top: 250px;
        padding-bottom: 150px;
    }
}
