﻿@import '../shared/palette';

.button-cmg {
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 1.2px;
    padding: 13px 26px;
    display: inline-block;
    text-align: center;

    &:focus {
        outline: none;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1679px) {
        font-size: 12px;
        padding: 13px 26px;
    }


    &.button-deep-see {
        color: #175A80;
        border: 2px solid #175A80;
        background-color: #FFFFFF;

        &:hover {
            color: #FFFFFF;
            border: 2px solid #175A80;
            background-color: #175A80;
        }
    }

    &.button-deep-see-solid {
        color: white;
        background-color: $deep-see;
        border: none;

        &:hover {
            background-color: rgba($deep-see, 0.8);
        }
    }

    &.button-bahia {
        color: white;
        background-color: $bahia;
        text-transform: uppercase;
        border: 2px solid $bahia;

        &:hover {
            color: #175A80;
            border: 2px solid #175A80;
            background-color: #FFFFFF;
        }
    }

    &.button-small {
        font-size: 12px;
        letter-spacing: 1.2px;
        padding: 15px 27px;
    }

    &:hover {
        text-decoration: none;
    }
}


.button-play {
    width: 80px;
    height: 80px;
    background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Shared_Images/play.svg');
    cursor: pointer;

    &:hover {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Shared_Images/play-gray.svg');
    }
}   