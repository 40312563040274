﻿html, body {
    margin: 0;
    -webkit-backface-visibility: hidden;
}

/* text-based popup styling */
.white-popup {
    position: relative;
    background: #FFF;
    padding: 25px;
    width: auto;
    max-width: 400px;
    margin: 0 auto;
}

.mfp-zoom-in {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: scale(0.8);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    /* animate in */
    &.mfp-ready {

        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }

        &.mfp-bg {
            background: black;
            opacity: 1;

            @media only screen and (min-width: 1200px) {
                background: #0b0b0b;
                opacity: 0.8;
            }
        }
    }
    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: scale(0.8);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }
    }
}

.mfp-container {

    &.mfp-iframe-holder {

        .mfp-content {
            max-width: 100%;

            .mfp-close {
                top: 0;
                right: 0;
                font-size: 40px;
            }

            @media only screen and (min-width: 1200px) {
                max-width: 1440px;
            }
        }
    }
}