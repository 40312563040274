﻿@import '../shared/palette';
@import '../shared/_mixins';

#hiw_page {

    .hero-section {
        background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
        background-position: 10% center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;


        .cmg-container {
            display: flex;
            flex-direction: column;
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .banner-text {


            h1 {
                font-family: 'Kadwa', serif;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0px;
                font-weight: 700;
                color: #175A80;
                margin-bottom: 0px;
            }

            h2 {
                font-size: 16px;
                color: #4D4D4D;
                font-weight: normal;
                margin-bottom: 28px;
                line-height: 24px;
                padding-top: 0px;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }
        }

        .banner-img {
            padding-top: 0px;

            .video-card {
                width: 100%;

                [data-role="video-player"] {
                    flex: 0 0 100%;
                    height: 193px;
                    overflow: hidden;
                    z-index: 2;
                    box-shadow: 0px 8px 20px #00000029;
                    border-radius: 5px;
                    position: relative;
                    //opacity: 0.35;

                    [data-role="thumbnail"] {
                        background-position: center;
                    }
                }

                [data-role="play-button"] {
                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }

        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
            //background-position: 0 center;
        }

        @media only screen and (min-width: 480px) {
            .cmg-container {
            }

            .banner-text {


                h1 {
                    font-size: 28px;
                    line-height: 46px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 28px;
                }

                p {

                    &:first-of-type {
                        // margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-bottom: 48px;
                    }
                }
            }

            .banner-img {

                .video-card {
                    width: 100%;

                    [data-role="video-player"] {
                        flex: initial;
                        height: 271px;

                        [data-role ="thumbnail"] {
                            width: 100%;
                            height: 271px;
                        }

                        [data-role="play-button"] {
                            img {
                                width: 70px;
                                height: 70px;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 768px) {

            .cmg-container {
                flex-direction: column;
                padding-top: 62px;
                padding-bottom: 91.77px;
            }

            .banner-text {
                h2 {
                    margin-bottom: 28px;
                    max-width: 457px;
                    padding-top: 0px;
                }

                p {
                    max-width: 457px;

                    &:last-of-type {
                        margin-bottom: 48px;
                    }
                }
            }

            .banner-img {
                .video-card {
                    width: 100%;

                    [data-role="video-player"] {
                        flex: initial;
                        height: 364px;

                        [data-role ="thumbnail"] {
                            width: 100%;
                            height: 364px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1024px) {

            .cmg-container {
                flex-direction: row;
                justify-content: space-between;
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .banner-text {
                padding-top: 62px;
                padding-bottom: 69.54px;
                width: 432px;
                max-width: unset;

                h2 {
                    margin-bottom: 28px;
                }

                p {
                    max-width: 100%;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            .banner-img {
                padding-top: 76px;

                .video-card {
                    width: 432px;

                    [data-role="video-player"] {
                        flex: initial;
                        height: 243px;

                        [data-role ="thumbnail"] {
                            width: 432px;
                            height: 243px;
                        }

                        [data-role ="play-button"] {
                            width: 71px;
                            height: 71px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1200px) {
            .cmg-container {
            }


            .banner-text {
                padding-top: 114px;
                padding-bottom: 114px;
                width: 432px;

                h1 {
                    font-size: 28px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 28px;
                    max-width: 500px;
                }

                p {

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            .banner-img {
                padding-top: 114px;

                .video-card {
                    width: 516px;

                    [data-role="video-player"] {
                        flex: initial;
                        height: 290px;

                        [data-role ="thumbnail"] {
                            width: 516px;
                            height: 290px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1440px) {
            .cmg-container {
            }

            .banner-text {
                width: 432px;

                h1 {
                    font-size: 40px;
                    line-height: 50px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 28px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            .banner-img {
            }
        }

        @media only screen and (min-width: 1920px) {
            .cmg-container {
            }

            .banner-text {
                padding-top: 157px;
                padding-bottom: 190px;
                width: 628px;

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 32px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            .banner-img {
                padding-top: 157px;

                .video-card {
                    width: 628px;

                    [data-role="video-player"] {
                        flex: initial;
                        height: 353px;

                        [data-role ="thumbnail"] {
                            width: 628px;
                            height: 353px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 2560px) {
            .cmg-container {
            }


            .banner-text {
                padding-top: 157px;
                padding-bottom: 268px;
                width: 700px;


                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 32px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 700px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            .banner-img {
                padding-top: 157px;

                .video-card {
                    width: 700px;

                    [data-role="video-player"] {
                        flex: initial;
                        height: 393px;

                        [data-role ="thumbnail"] {
                            width: 700px;
                            height: 393px;
                        }
                    }
                }
            }
        }
    }
}
