﻿@import '../palette';
@import url('https://use.fontawesome.com/releases/v5.0.9/css/all.css');

.site-navigator-mobile {

    &.show {


        .mobile-header {

            [data-role="close-button"] {
                display: block;
            }

            .microsite-logo {
                display: flex;
            }

            [data-role="open-button"] {
                display: none;
            }

            .navbar-brand {

                &[area="microsite"] {
                    display: none;
                }
            }
        }

        .mobile-header2 {
        }

        .mobile-menu {
            right: 0;
            transition: 0.5s ease;
        }
    }

    .mobile-header {
        position: fixed;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        padding: 0 32px;
        background-color: white;
        box-shadow: 0px 3px 6px #0000001A;
        z-index: 1;

        .microsite-logo {
            display: none;
            height: 70px;
            padding: 10px 0;
            align-items: center;
            justify-content: center;

            &-image {
                border-radius: 50%;
                border: 3px solid #9AC125;
                position: relative;
                width: 50px;
                height: 50px;

                img {
                    max-width: 100%;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                }
            }

            &-text {
                padding-left: 10px;

                h5 {
                    font-size: 16px;
                    color: #9AC125;
                    margin-bottom: 5px;
                }

                h6 {
                    font-size: 14px;
                    margin-bottom: 0;
                    color: $charcoal;
                }
            }
        }


        img {
            max-width: 110px;
        }

        a {
            color: #92C13B;
            letter-spacing: 0.25px;
            font-weight: 600;
            padding-left: 0px;

            [data-role="close-button"] {
                display: block;
            }

            [data-role="open-button"] {
                display: none;
            }
        }

        .start-button {
            border-radius: 5px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 1px;
            padding: 3px 11px;
            display: inline-block;
            text-align: center;
            background-color: #138890;
            color: #FFFFFF;
            letter-spacing: .75px;
        }

        [data-role="close-button"] {
            display: none;
        }

        [data-role="open-button"] {
            display: block;
        }

        @media only screen and (max-width: 320px) {
            img {
                max-width: 105px;
            }
        }

        @media only screen and (max-width: 350px) {
            a {
                color: #92C13B;
                letter-spacing: 0.25px;
                padding: 0 0px;
                font-weight: 600;
            }

            img {
                // max-width: 130px;
            }
        }

        @media only screen and (max-width: 430px) {
            a {
                color: #92C13B;
                letter-spacing: 0.25px;
                padding: 0 0px;
                font-weight: 600;
                font-size: 12px;
            }

            img {
                // max-width: 130px;
            }
        }

        svg {
            color: #C0C0C0;
        }
    }

    .mobile-header2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2A343D;


        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            font-size: 11px;
            line-height: 17px;
            letter-spacing: 1.1px;
            font-weight: 500;
        }
    }

    .mobile-menu {
        position: absolute;
        display: flex;
        flex-flow: column;
        height: calc(100vh - 70px);
        position: fixed;
        top: 70px;
        right: -100vw;
        background-color: rgba($white, 0.9);
        width: 100vw;
        transition: 0.5s ease;

        .mobile-header2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            justify-content: space-around;
            background-color: #2A343D;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 48px;
                font-size: 11px;
                line-height: 17px;
                letter-spacing: 1.1px;
                font-weight: 500;
                border-bottom: 1px solid #808080;

                &:last-of-type {
                    border-bottom: 0px solid #808080;
                }
            }

            .bcolor {
                color: #80B2CE;
            }

            .gcolor {
                color: #92C13B;
            }
        }

        nav {
            /*height: auto;*/
            height: 100%;
            padding-top: 35px;
            padding-bottom: 35px;
            overflow-y: scroll;

            .fa-chevron-up {
                display: none;
            }

            .menu-divider {
                width: 100%;
                margin: 10px 0 15px 0;
            }

            ul {
                margin-bottom: 0;
                padding: 0;

                &[name="retail"] {

                    > .expanded {

                        > span {
                            display: none;
                        }

                        > .menu-dropdown {
                            background-color: transparent;
                            padding: 0 !important;

                            > li > span,
                            > li > a {
                                font-size: 12px;
                                letter-spacing: 1.2px;
                                padding: 13px 45px 13px 30px;
                                color: $charcoal;
                            }

                            .expanded {

                                .menu-dropdown {
                                    padding-top: 7px;
                                    padding-bottom: 7px
                                }
                            }
                        }
                    }
                }

                li {
                    list-style-type: none;
                }

                .menu-link {

                    &-login {

                        a,
                        span {
                            color: $deep-see !important;
                        }
                    }

                    &.expanded {

                        > a,
                        > span {
                            //color: $deep-see !important;
                            .fa-chevron-down {
                                display: none;
                            }

                            .fa-chevron-up {
                                display: initial;
                            }
                        }

                        > .menu-dropdown {
                            max-height: 500px;
                            transition: max-height 0.25s ease-out;
                            padding: 0 0 0 15px;

                            > li {
                                &:not(.menu-link) {
                                    padding: 5px 0;
                                }
                            }
                        }
                    }

                    > a,
                    > span {
                        padding: 13px 45px 13px 30px;
                        font-family: 'Open Sans', sans-serif;
                        font-weight: 700;
                        font-size: 12px;
                        letter-spacing: 1.2px;
                        text-decoration: none;
                        color: $charcoal;
                        display: flex;
                        justify-content: space-between;

                        svg {
                            opacity: .3;
                        }
                    }

                    .menu-dropdown {
                        max-height: 0;
                        padding: 0;
                        overflow-y: hidden;
                        transition: max-height 0.25s ease-out, padding 0.5s ease-out;
                        background-color: rgba(#F0F0F0, 0.9);

                        > li {

                            a,
                            span {
                                padding-left: 30px;
                                font-size: 12px;
                                font-family: 'Open Sans', sans-serif;
                                letter-spacing: 1.4px;
                                font-weight: 700;
                                color: $suva-grey;
                            }
                        }
                    }
                }
            }

            .social {
                padding-left: 22px;
                padding-top: 30px;
            }
        }
    }

    @media only screen and (min-width:480px) {

        .mobile-header2 {
            justify-content: space-around;

            a {
                padding: 0px 25px;
            }
        }

        .mobile-menu {
            .mobile-header2 {
                flex-direction: row;

                a {
                    padding: 0px 25px;
                    border-bottom: 0px solid #808080;
                    border-right: 1px solid #808080;

                    &:last-of-type {
                        border-right: 0px solid #808080;
                        border-bottom: 0px solid #808080;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {

        .mobile-header {
            padding-left: 35px;
            padding-right: 35px;
        }

        .mobile-menu {
            right: -80vw;
            width: 80vw;

            nav {
                padding-top: 45px;
                padding-bottom: 45px;

                .menu-divider {
                    margin: 20px 0 30px 0;
                }

                ul {

                    &[name="retail"] {

                        > .expanded {

                            > .menu-dropdown {

                                > li > span,
                                > li > a {
                                    padding: 0 45px;
                                }

                                li {
                                    font-size: 16px;
                                    padding-top: 15px;
                                    padding-bottom: 15px;
                                }
                            }
                        }

                        .expanded {

                            > span {
                                padding-bottom: 15px !important;
                            }
                        }
                    }

                    .menu-link {

                        &.expanded {

                            > .menu-dropdown {
                                padding: 12px 0 12px 30px;
                            }
                        }

                        > a,
                        > span {
                            padding: 15px 45px;
                            font-size: 16px;
                        }

                        .menu-dropdown {

                            > li {

                                a,
                                span {
                                    padding: 12px 45px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }

                .social {
                    padding-left: 37px;
                    padding-top: 30px;
                }
            }
        }
    }
}
