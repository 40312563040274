.icon {
    mask-size: cover;
    background-repeat: no-repeat;
    display: inline-block;

    &--mortage {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/mortage.svg');
        width: 42px;
        height: 45px;
    }

    &--purchasuing {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/purchasuing.svg');
        width: 45px;
        height: 40px;
    }

    &--qualifying {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/qualifying.svg');
        width: 38px;
        height: 49px;
    }

    &--rent-buy {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/rent-buy.svg');
        width: 48px;
        height: 53px;
    }

    &--saving-calculator {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/saving-calculator.svg');
        width: 40px;
        height: 53px;
    }

    &--short-long {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/short-long.svg');
        width: 46px;
        height: 46px;
    }

    &--buydown {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/buydown.svg');
        width: 47px;
        height: 52px;
    }

    &--biWeekly {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/biWeekly.svg');
        width: 49px;
        height: 47px;
    }

    &--biWeekly {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Calculator_Images/biWeekly.svg');
        width: 49px;
        height: 47px;
    }

    &--list {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Shared_Images/list.svg');
        width: 13px;
        height: 17px;
    }

    &--calendar {
        background-image: url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Shared_Images/calendar.svg');
        width: 14px;
        height: 15px;
    }
}
