﻿@import '../shared/palette';
@import '../shared/_mixins';

#financial_advisors_page {

    .hero-section {
        background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
        background-position: 10% center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;


        .cmg-container {
            display: flex;
            flex-direction: column-reverse;
        }

        .banner-text {
            padding-top: 31px;
            padding-bottom: 69px;

            h1 {
                font-family: 'Kadwa', serif;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0px;
                font-weight: 700;
                color: #175A80;
                margin-bottom: 0px;
            }

            h2 {
                font-size: 16px;
                color: #4D4D4D;
                font-weight: normal;
                margin-bottom: 42px;
                line-height: 24px;
                padding-top: 16px;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 42px;
                }
            }

            .list-items {
                ul {
                    margin-bottom: 0px;
                    list-style-type: none;
                    list-style-position: outside;
                    margin-inline-start: 0px;
                    padding-inline-start: 0px;
                    // list-style-image: url(https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1608734277/AIOWebsite/Partners_Images/check.svg);
                }

                ul li {
                    display: flex;
                    margin-bottom: 19px;
                    align-items: center;

                    span {
                       
                        font-family: 'Open Sans', sans-serif;
                        color: #4D4D4D;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        padding-left: 13.23px;
                    }

                    img {
                        width: 19px;
                        height: 19px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }
        }


        .banner-img {
            padding-top: 26px;

            img {
                width: 100%;
            }
        }



        @media only screen and (min-width: 480px) {
            .cmg-container {
            }

            .banner-text {
                padding-bottom: 54px;

                h1 {
                    font-size: 28px;
                    line-height: 46px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    padding-top: 0px;
                }

                p {

                    &:first-of-type {
                        // margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-bottom: 42px;
                    }
                }
            }

            .banner-img {
            }
        }

        @media only screen and (min-width: 768px) {
            //background-position: 80px center;
            .cmg-container {
            }

            .banner-text {
                padding-top: 33px;
                padding-bottom: 60px;

                h2 {
                    margin-bottom: 42px;
                    max-width: 557px;
                }

                p {
                    max-width: 100%;

                    &:last-of-type {
                        margin-bottom: 42px;
                    }
                }

                .list-items {
                    margin-right: -30px;

                    ul li {

                        span {
                           
                        }
                    }
                }
            }

            .banner-img {
                padding-top: 60px;

                img {
                    width: 353px;
                    height: 215px;
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            //background-position: 180px center;
            .cmg-container {
                flex-direction: row;
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .banner-text {
                padding-top: 65px;
                padding-bottom: 65px;
                width: 70%;

                h2 {
                    margin-bottom: 42px;
                    max-width: 412px;
                }

                p {
                    max-width: 446px;
                }

                .list-items {
                    // margin-right: -10px;
                    ul li {
                        img {
                            width: 21px;
                            height: 21px;
                        }
                    }
                }
            }

            .banner-img {
                display: flex;
                align-items: center;
                margin-left: 88px;
                padding-top: 0px;

                img {
                    width: 369px;
                    height: 225px;
                }
            }
        }

        @media only screen and (min-width: 1200px) {
            //background-position: 60px center;
            .cmg-container {
            }


            .banner-text {
                padding-top: 85px;
                padding-bottom: 83px;

                h1 {
                    font-size: 28px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 42px;
                }

                p {

                    &:last-of-type {
                        margin-bottom: 42px;
                    }
                }

                .list-items {
                    ul li {
                        span {
                            padding-left: 14.44px;
                        }
                    }
                }
            }

            .banner-img {
                // padding-top: 201px;
                margin-left: 111.88px;

                img {
                    width: 516px;
                    height: 315px;
                }
            }
        }

        @media only screen and (min-width: 1440px) {
            //background-position: 60px center;
            .cmg-container {
            }

            .banner-text {
                padding-top: 84px;
                padding-bottom: 83px;

                h1 {
                    font-size: 40px;
                    line-height: 50px;
                }

                h2 {
                    max-width: 600px;
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 38px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 612px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 45px;
                    }
                }

                .list-items {
                    ul {
                    }

                    ul li {
                        img {
                            width: 19px;
                            height: 19px;
                        }

                        span {
                           
                            padding-left: 13.23px;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .banner-img {
                margin-left: 79.52px;
                padding-top: 10px;

                img {
                    width: 468px;
                    height: 286px;
                }
            }
        }

        @media only screen and (min-width: 1920px) {
            //background-position: 60px center;
            .cmg-container {
            }


            .banner-text {
                // padding-top: 111px;
                padding-top: 93px;
                padding-bottom: 112px;

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 38px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }

                    &:last-of-type {
                        margin-bottom: 45px;
                    }
                }

                .list-items {

                    ul li {
                        img {
                            width: 21px;
                            height: 21px;
                        }

                        span {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .banner-img {
                margin-left: 153.88px;

                img {
                    width: 598px;
                    height: 365px;
                }
            }
        }

        @media only screen and (min-width: 2560px) {

            .banner-text {
                padding-top: 216px;
                padding-bottom: 215px;
            }

            .banner-img {
                margin-left: 175.88px;

                img {
                    width: 724px;
                    height: 442px;
                }
            }
        }
    }
}
