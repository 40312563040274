﻿@import '../palette';
@import '../_mixins';

.site-navigator {
    display: none;


    @media only screen and (min-width: 1200px) {
        height:122px;
        display: flex;
        align-items:center;
        position: fixed;
        width: 100%;
        z-index: 100;
        top: 44px;
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: transparent;
        transition: 0.4s all ease;
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.05) 5%, rgba(255,255,255,0.28) 15%, rgba(255,255,255,0.6) 25%, rgba(255,255,255,0.95) 55%);

        &.bg-white {
            display:flex;
           box-shadow: 0 .125rem .25rem rgba($black, 0.075);
            padding-top: 0;
            padding-bottom: 0;
            transition: 0.4s all ease;

            nav {

                .dropdown-menu {
                    border: #707070 !important;
                    margin-top: 5px;

                    a {

                        &:hover {
                            background-color: rgba($black, 0.2);
                        }
                    }
                }
            }
        }

        nav {
            @include wrapper(1620px, true);

            .navbar-brand {
                border-right: 0px solid rgba($charcoal, .2);
                padding-right: 0;
                padding-bottom: 0.5rem;
                margin-right: 0.5rem;
                width: 195px;

                img {
                    width: 90%;
                    display: inline-block;
                    margin: 0 1px;
                    -webkit-perspective: 1000;
                    -webkit-backface-visibility: hidden;
                }
            }

            .navbar-collapse {

                .navbar-nav {
                    margin-top: .5rem;
                    align-items: center;

                    .nav-item {
                        margin-left: 20px;

                        a {

                            label {
                                cursor: pointer;
                            }
                        }

                        .nav-link {
                            color: $charcoal;
                            font-family: 'Open Sans', sans-serif;
                            font-weight: 900;
                            font-size: 12px;
                            letter-spacing: 1.2px;
                            cursor: pointer;

                            &:last-of-type {
                                margin-right: 0px;
                                padding-right: 0px;
                            }

                            &.dropdown-toggle {

                                &:after {
                                    display: none;
                                }
                            }

                            &:hover {
                                text-decoration: none;
                                color: $black;
                                text-shadow: 1px 1px rgba($white, 0.1);
                            }

                            svg {
                                opacity: .3;
                            }
                        }
                    }

                    &.nav-tools {

                        .nav-item {
                            margin-left: 7px;

                            .microsite-logo {
                                display: flex;
                                align-items: center;
                                margin-right: 20px;

                                &-image {
                                    border-radius: 50%;
                                    border: 3px solid #9AC125;
                                    position: relative;
                                    width: 50px;
                                    height: 50px;

                                    img {
                                        max-width: 100%;
                                        height: auto;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }

                                &-text {
                                    padding-left: 10px;

                                    h5 {
                                        font-size: 16px;
                                        color: #9AC125;
                                        margin-bottom: 5px;
                                    }

                                    h6 {
                                        font-size: 14px;
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dropdown-menu {
                background-color: #E9E9E9;
                transform: translate3d(-20px, 0px, 0px) !important;
                border: none;
                border-radius: 0 !important;

                a {
                    color: $charcoal;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 900;
                    font-size: 12px;
                    letter-spacing: .6px;
                    margin-bottom: .5rem;

                    &:hover {
                        background-color: rgba($dim-grey, 0.2);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1280px) {

        nav {

            .navbar-collapse {

                .navbar-nav {

                    .nav-item {
                        margin-left: 25px;

                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1440px) {

        nav {

            .navbar-collapse {

                .navbar-nav {

                    .nav-item {
                        margin-left: 40px;

                        .nav-link {
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1680px) {

        nav {

            .navbar-brand {
                padding-right: 1.5rem;
                margin-right: 1rem;
                width: initial;

                img {
                    width: 100%;
                }
            }

            .navbar-collapse {

                .navbar-nav {

                    .nav-item {
                        margin-left: 59px;

                        .nav-link {
                            letter-spacing: 1px;
                        }
                    }

                    &.nav-tools {

                        .nav-item {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
}
