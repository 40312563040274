﻿.nav-tabs-cmg {
    width: 100%;

    .nav-tabs {
        display: none;
    }

    .nav-tabs-mobile {
        display: flex;

        &[nav-mobile="select"] {
            justify-content: center;
            position: relative;
            padding: 30px 0;
            margin: 0 auto;

            select {
                padding: 5px 0;
                color: #138890;
                font-family: 'Open Sans', sans-serif;
                font-size: 12px;
                font-weight: 700;
                border: none;
                box-shadow: none;
                background: transparent url('https://res.cloudinary.com/dvbdysuf5/image/upload/CMG_Web_Resources/Shared_Images/arrow-select.svg') right center no-repeat;
                -webkit-appearance: none;
                letter-spacing: 1.2px;
                line-height: 17px;

                option {
                    font-family: 'Open Sans', sans-serif;
                    color: #4D4D4D;
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: .6px;
                    line-height: 17px;
                }

                &:hover {
                    cursor: pointer;
                }

                &:focus {
                    outline: none;
                }
            }
        }


        &[nav-mobile="button"] {
            flex-direction: column;

            button {
                margin-bottom: 20px;
                width: 100%;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {

        .nav-tabs {
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-bottom: none;
            padding: 20px 60px;

            .nav-item {
                cursor: pointer;

                .nav-link {
                    display: inline-block;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 12px;
                    font-weight: 900;
                    letter-spacing: 1px;
                    color: #A8A8A8;
                    padding: 0;
                    border: none;
                    border-bottom: 3px solid transparent;

                    &.active {
                        border-bottom: 3px solid #B1D34E;
                        padding-bottom: 3px;
                        color: #A8A8A8;
                    }

                    &:hover,
                    &:focus {
                        //outline: none;
                        &:not(.active) {
                            border-color: transparent;
                        }
                    }
                }
            }
        }

        .nav-tabs-mobile {
            display: none;
        }
    }

    @media only screen and (min-width: 992px) {
        max-width: 800px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 950px;
        padding: 30px 0;
        margin: 0 auto;

        .nav-tabs {
            padding: 20px 0;
            justify-content: space-between;

            .nav-item {

                .nav-link {
                    font-size: 16px;
                }
            }
        }
    }
}
