﻿@import '../palette';
@import '../_mixins';

header {
    position: fixed;
    top: 0;
    z-index: 101;
    width: 100%;

    .site-header {
        background-color: #2A343D;
        color: rgba(white, 0.8);
        height: 44px;

        .cmg-container {
            @include wrapper(1620px, true);
            align-items: center;
            width: 100%;
            height: 100%;
        }

        img {
            height: 21px;
        }

        a {
            text-decoration: none;
            color: rgba(white, 0.8);


            &:hover {
                color: white;
                text-decoration: none;
            }
        }

        > a {
            &:last-of-type {
                padding-right: 0;
                margin-left: 1rem;
            }
        }

        .payment-center {
            padding: 5px 20px;
            border: 1px solid #808080;
            border-radius: 3px;
        }

        .bcolor-header {
            color: #80B2CE;
        }

        .gcolor-header {
            color: #92C13B;
        }

        [name='social'] {
            padding-right: 5px;
            border-right: 1px solid rgba(255, 255, 255, .8);
        }

        [name='contact'] {
            padding-left: 0px;

            a {
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1.2px;

                label {
                    vertical-align: middle;
                    cursor: pointer;
                    letter-spacing: 1px;
                }
            }
        }

        [name='cmg'] {

            .dropdown {
                font-size: .75rem;
            }

            .dropdown-toggle {
                font-weight: 800;
                cursor: pointer;

                svg {
                    color: $dim-grey;
                    vertical-align: middle;
                }

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                background-color: $jumbo;
                border: $dim-grey;
                border-radius: 0 0 .25rem .25rem;
                transform: translate3d(-20px, 32px, 0px) !important;
                margin: 0px;

                a {
                    color: white;

                    &:hover {
                        background-color: $dim-grey;
                    }
                }
            }

            a {
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                font-size: 11px;
                line-height: 17px;
                letter-spacing: 1.1px;
            }
        }
    }
}

