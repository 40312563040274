[data-role="video-player"] [data-role="thumbnail"] {
  position: relative;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat; }
  [data-role="video-player"] [data-role="thumbnail"].thumbnail-center {
    background-position: center; }

[data-role="video-player"] [data-role="play-button"] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
