﻿#home_page, #embed_code_page, #npb_home_page, #mbi_home_page, #cmt_home_page {

    .calculator-section {
        background-image: url("https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1605641201/AIOWebsite/Shared_Images/interior-bg.png");
        background-position: 20% center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        h1 {
            font-family: 'Kadwa', serif;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: 0px;
            font-weight: 700;
            color: #175A80;
            margin-bottom: 0px;
            padding-top: 62px;
        }

        .aio-simulator__info {
            display: none;
        }

        .custom-select {
            height: auto !important;
            border: 0 !important;
            padding: 0 !important;
        }

        .aio-simulator {
            padding: 0px;
        }

        .cmg-container {
            // @include wrapper(1400px, true);
            display: flex;
            flex-direction: column;
            /* justify-content: center;
            align-items: center;*/
            padding-top: 50px;
            padding-bottom: 27.96px;
            position: relative;
            /*  text-align: center;*/
        }

        .content-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 32px;

            h2 {
                font-family: 'Kadwa', serif;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0px;
                font-weight: 700;
                color: #175A80;
                margin-bottom: 12.66px;
                max-width: 100%;
            }


            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;
                margin-bottom: 2px;
                text-align: center;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 0px;
                }
            }
        }

        .div-iframe {
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: transparent;
            width: 100%;
            height: auto;

            iframe {
                border-width: 0px;
                background-color: transparent;
                border: 0px solid #707070;
            }
        }

        iframe {
            border-width: 0px;
            background-color: transparent;
            border: 0px solid #707070;
        }

        .iframe-class {
            width: 100%;
            height: 1000px;
        }

        @media only screen and (min-width: 480px) {

            .cmg-container {
                padding-bottom: 20.02px;
            }

            .content-text {
                margin-bottom: 40px;

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 10.66px;
                    max-width: 340px;
                }


                p {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 14px;
                }
            }

            .iframe-class {
                width: 100%;
                height: 600px;
            }
        }


        @media only screen and (min-width: 768px) {

            .cmg-container {
                padding-top: 73px;
                padding-bottom: 63.96px;
            }

            .content-text {
                margin-bottom: 44px;

                h2 {
                    margin-bottom: 10.66px;
                    max-width: 648px;
                }

                p {
                    max-width: 648px;
                }
            }
        }

        @media only screen and (min-width: 1024px) {

            .cmg-container {
                padding-top: 73px;
                padding-bottom: 41.96px;
            }

            .content-text {

                h2 {
                    margin-bottom: 10.66px;
                    max-width: 648px;
                }

                p {
                    max-width: 794px;
                }
            }
        }

        @media only screen and (min-width: 1200px) {
            background-attachment: fixed;
            background-position: unset;

            .cmg-container {
                padding-top: 89px;
                padding-bottom: 77.57px;
            }


            .content-text {
                margin-bottom: 51px;

                h2 {
                    font-size: 20px;
                    line-height: 28px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 981px;
                    margin-bottom: 0px;
                }
            }

            .div-iframe {
                //width: 100%;
                // padding: 75px 300px;
            }
        }

        @media only screen and (min-width: 1440px) {

            .cmg-container {
                padding-top: 89px;
                padding-bottom: 77.57px;
            }


            .content-text {
                margin-bottom: 52px;

                h2 {
                    font-size: 20px;
                    line-height: 28px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 981px;
                    margin-bottom: 0px;
                }
            }

            .div-iframe {
                //width: 100%;
                // padding: 75px 300px;
            }
        }

        @media only screen and (min-width: 1920px) {

            .cmg-container {
                padding-top: 122px;
                padding-bottom: 92.96px;
            }


            .content-text {
                margin-bottom: 69px;

                p {
                    margin-bottom: 0px;
                    max-width: 1099px;
                }
            }

            .div-iframe {
                //width: 100%;
                // padding: 75px 300px;
            }

            .iframe-class {
                width: 100%;
                height: 1440px;
            }
        }

        @media only screen and (min-width: 2560px) {

            .content-text {
                margin-bottom: 70px;

                p {
                    margin-bottom: 0px;
                    max-width: 1099px;
                }
            }
        }
    }
}
