﻿@import '../shared/palette';
@import '../shared/_mixins';

 #npb_home_page, #mbi_home_page {

    .hero-section {
      

        .cmg-container {
            padding-top: 62px;
            padding-bottom: 20px;
            position: relative;
        }

        .banner-text {

            h1 {
                font-family: 'Kadwa', serif;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0px;
                font-weight: 700;
                color: #175A80;
                margin-bottom: 0px;
            }

            h2 {
                font-size: 16px;
                color: #4D4D4D;
                font-weight: normal;
                margin-bottom: 27.96px;
                line-height: 24px;
                padding-top: 16px;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 0px;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }

            .div-buttons {
                display: flex;
                flex-direction: column;

                a {
                    width: 100%;
                    text-align: center;

                    &:first-of-type {
                        margin-bottom: 16px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
          //  background-position: 0 center;
        }

        @media only screen and (min-width: 480px) {
            .cmg-container {
                padding-top: 108px;
               // padding-bottom: 94px;
            }

            .banner-text {

                h1 {
                    font-size: 28px;
                    line-height: 46px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    padding-top: 0px;
                }

                p {

                    &:first-of-type {
                        margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-bottom: 50px;
                    }
                }

                .div-buttons {
                    display: flex;
                    flex-direction: column;

                    a {
                        width: 100%;
                        text-align: center;

                        &:first-of-type {
                            margin-bottom: 16px;
                        }

                        &:last-of-type {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 768px) {
           // background-position: 80px center;

            .cmg-container {
                padding-top: 108px;
               // padding-bottom: 120px;
            }

            .banner-text {

                h2 {
                    margin-bottom: 23.96px;
                    max-width: 457px;
                }

                p {
                    max-width: 457px;
                }

                .div-buttons {
                    flex-direction: row;

                    a {
                        width: 165px;

                        &:first-of-type {
                            margin-right: 22.74px;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1024px) {
           // background-position: 220px center;

            .cmg-container {
               // padding-bottom: 105px;
            }
        }

        @media only screen and (min-width: 1200px) {
           /* background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Home_Images/home_hero.png");
            background-position: 60px center;*/
            /* background-attachment: fixed;
            background-position: unset;*/
            .cmg-container {
                padding-top: 244px;
               // padding-bottom: 168.5px;
            }


            .banner-text {

                h1 {
                    font-size: 28px;
                }

                h2 {
                    font-size: 18px;
                    max-width: 457px;
                    margin-bottom: 23.96px;
                }

                p {
                    max-width: 457px;
                }

                .div-buttons {
                    flex-direction: row;

                    a {
                        // width:
                        &:first-of-type {
                            margin-right: 22.74px;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1440px) {
            .banner-text {

                h1 {
                    line-height: 50px;
                }
            }
        }

        @media only screen and (min-width: 1920px) {
           // background-position: unset;

            .cmg-container {
               // padding-bottom: 197px;
            }

            .banner-text {

                h1 {
                    font-size: 40px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 31.95px;
                    max-width: 516px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 516px;
                }

                .div-buttons {
                    flex-direction: row;

                    a {
                        // width:
                        &:first-of-type {
                            margin-right: 22.74px;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 2560px) {
           /* background-size: contain;
            background-position: top right;
*/
            .cmg-container {
                padding-top: 348px;
               // padding-bottom: 236px;
            }
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          //  background-attachment: scroll;
        }
    }
}
