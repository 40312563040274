﻿#webinar_retail_page, #webinar_jv_page, #webinar_sp_page, #webinar_tpo_page {

    .webinar-section {
        padding-top: 32px;
        padding-bottom: 8px;
        background-color: #F7F7F7;

        .cmg-container {
            display: flex;
            flex-direction: column;
        }

        .button-cmg {
            padding: 12px 33px;
        }

        .one {
            width: 100%;
        }

        .two {
            width: 100%;
        }

        .three {
            width: 100%;
        }

        .item {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            border: 1px solid #C9C9C9;
            border-radius: 5px;
            background-color: #FFFFFF;

            .webinar-head {
                background-color: #F7F7F7;
                border-radius: 5px 5px 0px 0px;
                padding: 25px;

                .info-header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .info {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 12px;

                    img {
                        width: 18px;
                        //height: 16px;
                        margin-right: 8px;
                    }

                    p {
                        margin-top: 0px;

                        span {
                        }
                    }

                    .marginBottom {
                        margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }

                .div-button {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    a {
                        width: 100%;
                    }
                }
            }

            .webinar-body {
                background-color: #FFFFFF;
                border-radius: 0px 0px 5px 5px;
                padding: 25px;

                h3 {
                    color: #175A80;
                    font-family: 'Kadwa', serif;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: 0px;
                    font-weight: 700;
                }
            }

            p {
                color: #4D4D4D;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0px;
                font-weight: normal;
                margin-bottom: 20px;

                span {
                    font-weight: 700;
                }

                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }

        @media only screen and (min-width: 480px) {
            padding-top: 60px;
            padding-bottom: 35px;

            .item {

                .webinar-head {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 20px 40px;


                    .info {
                        img {
                            margin-right: 8px;
                        }

                        p {
                            span {
                            }
                        }
                        /* .marginBottom {
                            margin-bottom: 10px;
                        }*/
                    }

                    .div-button {
                        margin-top: 0px;

                        a {
                            width: 130px;
                        }
                    }
                }

                .webinar-body {
                    padding: 40px;
                }
            }
        }

        @media only screen and (min-width: 768px) {
            padding-top: 85px;
            padding-bottom: 60px;

            .item {

                .webinar-head {
                    display: flex;
                    flex-direction: row;
                    padding: 15px 40px;

                    .info-header {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .info {
                        margin-right: 24px;

                        img {
                        }

                        p {
                            margin-bottom: 0px;
                        }

                        .marginBottom {
                            margin-bottom: 0px;
                        }
                    }

                    .div-button {
                        margin-top: 0px;
                    }
                }

                .webinar-body {
                    padding: 40px;
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            .cmg-container {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .one {
                width: 49%;
            }

            .two {
                width: 49%;
            }

            .three {
                width: 32%;
            }

            .item {
                //  margin-bottom: 0px;
                // margin-right: 32px;
                .webinar-head {
                    display: flex;
                    flex-direction: row;

                    .info-header {
                        flex-direction: column;
                    }

                    .info {

                        img {
                        }

                        p {
                            margin-bottom: 0px;
                        }
                    }

                    .div-button {
                        margin-top: 0px;
                    }
                }

                .webinar-body {
                    padding: 40px;
                }
            }
        }

        @media only screen and (min-width: 1280px) {
            padding-top: 85px;
            padding-bottom: 60px;



            .item {

                .webinar-head {

                    .info {

                        img {
                        }

                        p {
                            margin-bottom: 0px;
                        }
                    }

                    .div-button {
                        margin-top: 0px;
                        margin-left: 0px;
                    }
                }

                .webinar-body {
                    padding: 40px;
                    height: auto;
                }
            }
        }

        @media only screen and (min-width: 1440px) {

            .cmg-container {
                flex-direction: row;
                flex-wrap: wrap;
            }

            .item {

                .webinar-head {

                    .info {

                        img {
                        }

                        p {
                            margin-bottom: 0px;
                        }
                    }

                    .div-button {
                        margin-top: 0px;
                    }
                }

                .webinar-body {
                    padding: 40px;
                    height: auto;
                }
            }
        }

        @media only screen and (min-width: 1920px) {

            .cmg-container {
                flex-direction: row;
            }

            .item {

                .webinar-head {

                    .info {

                        img {
                        }

                        p {
                            margin-bottom: 0px;
                        }
                    }

                    .div-button {
                        margin-top: 0px;
                    }
                }

                .webinar-body {
                    padding: 40px;
                    height: auto;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                }

                &:last-of-type {
                    //  margin-right: 0px;
                }
            }
        }

        @media only screen and (min-width: 2560px) {


            .cmg-container {
                flex-direction: row;
            }

            .item {

                .webinar-head {

                    .info {

                        img {
                        }

                        p {
                            margin-bottom: 0px;
                        }
                    }

                    .div-button {
                        margin-top: 0px;
                    }
                }

                .webinar-body {
                    padding: 40px;
                    height: auto;
                }

                &:last-of-type {
                    //  margin-right: 0px;
                }
            }
        }
    }
}
