﻿.testimonialwidget-full-partial-view {

    #ttMob {
        display: block;
    }

    #ttTab {
        display: none;
    }

    #ttWeb {
        display: none;
    }

    .carousel {

        &-indicators {
            position: relative;
            margin: 30px 15% 45px;

            > li {
                width: 8px;
                height: 8px;
                margin: 0 4px;
                border-radius: 50%;
                background-color: #A8A8A8;
            }
        }
    }

    .show-read-more {
        .more-text {
            display: none;
        }

        .read-more {
            font-weight: 600;
            font-style: italic;
            color: #4D4D4D;
        }
    }

    .carousel-indicators {
        position: relative;
        margin: 0px;
        margin-top: 48px;

        li {
            width: 15px;
            height: 15px;
            margin: 0 10px;
            border-radius: 50%;
            border: 2px solid #0D4362;
            background-color: #0D4362;
            opacity: .5;

            &:active {
            }
        }

        .active {
            border: 2px solid #0D4362;
            background-color: #FFFFFF;
        }
    }

    .testimonial-item {
        width: 100%;

        .icon {
            margin-bottom: 16px;

            img {
                width: 60px;
                height: 48px;
            }
        }

        .content {
            h3 {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: 700;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                margin-bottom: 0px;
                width: 100%;
                span{
                    font-weight:normal;
                }
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 16.24px;
                width: 100%;
            }
        }

        &:first-of-type {
            // margin-bottom: 68px;
        }
    }

    @media only screen and (min-width: 768px) {

        #ttMob {
            display: none;
        }

        #ttTab {
            display: block;
        }

        #ttWeb {
            display: none;
        }


        .testimonial-row {
            display: flex;
            flex-direction: row;
        }

        .carousel-item {
            width: 100%;
        }

        .testimonial-item {
            width: 46%;

            &:nth-child(odd) {
                margin-right: 45.75px;
            }
        }
    }

    @media only screen and (min-width: 1024px) {
    }

    @media only screen and (min-width: 1200px) {

        #ttMob {
            display: none;
        }

        #ttTab {
            display: none;
        }

        #ttWeb {
            display: block;
        }

        .carousel-item {
            width: 100%;
        }


        .testimonial-item {
            width: 320px;
            margin-right: 56.5px;

            &:nth-child(3n) {
                margin-right: 0px !important;
            }

            .icon {
                margin-bottom: 15.76px;
            }

            .content {
                p {
                    margin-bottom: 15.76px;
                }
            }
        }
    }

    @media only screen and (min-width: 1440px) {
        .testimonial-item {
            width: 346px;
            margin-right: 59.38px;

            &:nth-child(3n) {
                margin-right: 0px !important;
            }

            .icon {
                margin-bottom: 15.76px;
            }

            .content {
                p {
                    margin-bottom: 16.1px;
                }
            }
        }
    }

    @media only screen and (min-width: 1920px) {
        .testimonial-item {
            width: 407px;
            margin-right: 72px;

            &:nth-child(3n) {
                margin-right: 0px !important;
            }
        }
    }

    @media only screen and (min-width: 2560px) {
        .testimonial-item {
            width: 449px;
            margin-right: 72px;

            &:nth-child(3n) {
                margin-right: 0px !important;
            }
        }
    }
}
