﻿@import '../shared/palette';
@import '../shared/_mixins';

#testimonial_page {

    .testimonial-section {
        background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
        background-attachment: fixed;
        background-position: 10% center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .cmg-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 60px;
            padding-bottom: 68px;
        }

        .divider {
            width: 100%;
            border-bottom: 1px solid #EFEFEF;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        h1 {
            font-family: 'Kadwa', serif;
            font-size: 24px;
            letter-spacing: 0px;
            font-weight: 700;
            color: #175A80;
            margin-bottom: 40px;
        }


        h2 {
            font-size: 16px;
            color: #4D4D4D;
            font-weight: normal;
            margin-bottom: 39.95px;
            line-height: 24px;
            margin-top: 0px;

            &:first-of-type {
                margin-top: 0px;
            }
        }

        .carousel-indicators {
            width: 103px;
            margin: 0 auto;
            margin-top: 45px;

            .carousel-control-prev, .carousel-control-next {
                span {
                    color: #80B2CE;
                    width: 12px;
                    height: 19px;
                }
            }

            .active {
                border: 0px solid #0D4362;
            }
        }



        .video-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .video-card {
                width: 100%;
                margin-top: 20.5px;
                margin-bottom: 20.5px;

                &:first-of-type {
                    margin-top: 0px;
                    margin-bottom: 20.5px;
                }

                &:last-of-type {
                    margin-top: 20.5px;
                    margin-bottom: 0px;
                }

                [data-role="video-player"] {
                    flex: 0 0 100%;
                    height: 193px;
                    overflow: hidden;
                    z-index: 2;
                    box-shadow: 0px 8px 20px #00000029;
                    border-radius: 5px;
                    position: relative;

                    [data-role ="play-button"] {
                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }

                [data-role ="title"] {
                    margin-top: 17px;
                    color: #4D4D4D;
                    text-align: center;

                    p {
                        font-size: 14px;
                        line-height: 26px;
                        font-family: Kadwa,serif;
                        font-weight: normal;
                        margin-bottom: 0px;
                    }
                }
            }
        }




        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
            background-position: 0 center;
        }

        @media only screen and (min-width: 480px) {
            .cmg-container {
                padding-top: 70px;
                padding-bottom: 98px;
            }

            h1 {
                font-size: 28px;
                margin-bottom: 41px;
            }

            h2 {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 42.95px;
            }


            .video-list {
                .video-card {
                    width: 416px;
                    margin-top: 28.5px;
                    margin-bottom: 28.5px;

                    &:first-of-type {
                        margin-top: 0px;
                        margin-bottom: 28.5px;
                    }

                    &:last-of-type {
                        margin-top: 28.5px;
                        margin-bottom: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 271px;

                        [data-role ="thumbnail"] {
                            width: 416px;
                            height: 271px;
                        }

                        [data-role ="play-button"] {
                            img {
                                width: 82px;
                                height: 82px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 25px;

                        p {
                            font-size: 16px;
                            line-height: 36px;
                        }
                    }
                }
            }
        }


        @media only screen and (min-width: 768px) {

            .cmg-container {
                padding-top: 67px;
                padding-bottom: 71px;
            }

            h1 {
                margin-bottom: 42px;
            }

            h2 {
                margin-bottom: 43.95px;
                max-width: 457px;
            }

            .carousel-indicators {
                width: unset;

                .active {
                    border: 2px solid #0D4362;
                }
            }

            .video-list {
                flex-direction: row;
                justify-content: flex-start;
                overflow-x: scroll;
                margin-top: 62px;
                margin-bottom: 44px;

                .video-card {
                    padding-top: 0px;
                    padding-bottom: 0px;
                    width: 361px;
                    margin-left: 23px;
                    margin-right: 23px;
                    margin-top: 0px;
                    margin-bottom: 0px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 23px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-left: 23px;
                        margin-right: 0px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 235px;

                        [data-role ="thumbnail"] {
                            width: 361px;
                            height: 235px;
                        }

                        [data-role ="play-button"] {
                            img {
                                width: 71px;
                                height: 71px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 21px;

                        p {
                            font-size: 16px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1024px) {

            .cmg-container {
                padding-top: 72px;
                padding-bottom: 66px;
            }

            h2 {
                margin-bottom: 35.95px;
            }
        }

        @media only screen and (min-width: 1200px) {

            .cmg-container {
                justify-content: flex-start;
                padding-top: 66px;
                padding-bottom: 78px;
            }

            h1 {
                font-size: 28px;
                margin-bottom: 41px;
            }

            h2 {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 35.46px;
                max-width: 500px;
            }


            .video-list {
                overflow-x: unset;
                justify-content: flex-start;
                margin-top: 0px;
                margin-bottom: 0px;

                .video-card {
                    margin-left: 27.5px;
                    margin-right: 27.5px;
                    flex-direction: column;
                    width: 321px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 27.5px;
                    }

                    &:last-of-type {
                        margin-left: 27.5px;
                        margin-right: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 244px;

                        [data-role ="thumbnail"] {
                            width: 321px;
                            height: 244px;
                        }

                        [data-role ="play-button"] {
                            img {
                                width: 63px;
                                height: 63px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 20px;

                        p {
                            font-size: 16px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1440px) {

            .cmg-container {
                padding-top: 82px;
                padding-bottom: 143px;
            }


            h1 {
                font-size: 40px;
                margin-bottom: 40px;
            }

            h2 {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 34.95px;
            }


            .video-list {
                overflow-x: unset;
                margin-top: 0px;
                margin-bottom: 0px;

                .video-card {
                    margin-left: 30px;
                    margin-right: 30px;
                    flex-direction: column;
                    width: 346px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 30px;
                    }

                    &:last-of-type {
                        margin-left: 30px;
                        margin-right: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 261px;

                        [data-role ="thumbnail"] {
                            width: 346px;
                            height: 261px;
                        }

                        [data-role ="play-button"] {
                            img {
                                width: 69px;
                                height: 68px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 23px;

                        p {
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1920px) {

            .cmg-container {
                padding-top: 100px;
                padding-bottom: 144px;
            }

            h1 {
                font-size: 40px;
                margin-bottom: 41px;
            }

            h2 {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 34.95px;
            }


            .video-list {
                overflow-x: unset;
                margin-top: 0px;
                margin-bottom: 0px;

                .video-card {
                    margin-left: 36px;
                    margin-right: 36px;
                    flex-direction: column;
                    width: 407px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 36px;
                    }

                    &:last-of-type {
                        margin-left: 36px;
                        margin-right: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 307px;

                        [data-role ="thumbnail"] {
                            width: 407px;
                            height: 307px;
                        }

                        [data-role ="play-button"] {
                            img {
                                width: 80px;
                                height: 80px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 31px;

                        p {
                            font-size: 20px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 2560px) {

            .cmg-container {
                padding-top: 111px;
                padding-bottom: 138px;
            }


            .video-list {
                overflow-x: unset;
                margin-top: 0px;
                margin-bottom: 0px;

                .video-card {
                    margin-left: 42px;
                    margin-right: 42px;
                    flex-direction: column;
                    width: 449px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 42px;
                    }

                    &:last-of-type {
                        margin-left: 42px;
                        margin-right: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 339px;

                        [data-role ="thumbnail"] {
                            width: 449px;
                            height: 339px;
                        }

                        [data-role ="play-button"] {
                            img {
                                width: 88px;
                                height: 88px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 36px;

                        p {
                            font-size: 20px;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
    }
}
