﻿#home_page {
    .video-section {
        background-color: #F7F7F7;

        .cmg-container {
            display: flex;
            justify-content: center;
            padding-top: 50px;
            padding-bottom: 50px;
        }


        .video-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width:100%;
            .video-card {
                width: 100%;
                margin-top: 17px;
                margin-bottom: 17px;

                &:first-of-type {
                    margin-top: 0px;
                    margin-bottom: 17px;
                }

                &:last-of-type {
                    margin-top: 17px;
                    margin-bottom: 0px;
                }

                [data-role="video-player"] {
                    flex: 0 0 100%;
                    height: 193px;
                    overflow: hidden;
                    z-index: 2;
                    box-shadow: 0px 8px 20px #00000029;
                    border-radius: 5px;
                    position: relative;

                    [data-role ="play-button"] {
                        img{
                        width: 50px;
                        height: 50px;
                        }
                    }
                }

                [data-role ="title"] {
                    margin-top: 13px;
                    color: #4D4D4D;
                    text-align: center;

                    p {
                        font-size: 14px;
                        line-height: 26px;
                        font-family: Kadwa,serif;
                        font-weight: normal;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        @media only screen and (min-width: 480px) {
            .video-list {
                .video-card {
                    width: 416px;
                    margin-top: 25px;
                    margin-bottom: 25px;

                    &:first-of-type {
                        margin-top: 0px;
                        margin-bottom: 25px;
                    }

                    &:last-of-type {
                        margin-top: 25px;
                        margin-bottom: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 271px;

                        [data-role ="thumbnail"] {
                            width: 416px;
                            height: 271px;
                        }

                        [data-role ="play-button"] {
                            img{
                            width: 82px;
                            height: 82px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 20px;

                        p {
                            font-size: 16px;
                            line-height: 36px;
                        }
                    }
                }
            }
        }


        @media only screen and (min-width: 768px) {
            .cmg-container {
                padding-top: 0px;
                padding-bottom: 0px;
                padding-right: 0px;
            }

            .video-list {
                flex-direction: row;
                justify-content: flex-start;
                overflow-x: scroll;
                margin-top: 62px;
                margin-bottom: 44px;

                .video-card {
                    padding-top: 0px;
                    padding-bottom: 0px;
                    width: 361px;
                    margin-left: 23px;
                    margin-right: 23px;
                    margin-top: 0px;
                    margin-bottom: 0px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 23px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-left: 23px;
                        margin-right: 0px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 235px;

                        [data-role ="thumbnail"] {
                            width: 361px;
                            height: 235px;
                        }

                        [data-role ="play-button"] {
                            img{
                            width: 71px;
                            height: 71px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 16px;

                        p {
                            font-size: 16px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1024px) {
        }

        @media only screen and (min-width: 1200px) {
            .cmg-container {
                justify-content: center;
                padding-top: 110px;
                padding-bottom: 85px;
                padding-right: 100px;
            }

            .video-list {
                overflow-x: unset;
                justify-content: center;
                margin-top: 0px;
                margin-bottom: 0px;

                .video-card {
                    margin-left: 29px;
                    margin-right: 29px;
                    flex-direction: column;
                    width: 321px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 29px;
                    }

                    &:last-of-type {
                        margin-left: 29px;
                        margin-right: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 244px;

                        [data-role ="thumbnail"] {
                            width: 321px;
                            height: 244px;
                        }

                        [data-role ="play-button"] {
                            img{
                            width: 63px;
                            height: 63px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 15px;

                        p {
                            font-size: 16px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1440px) {
            .cmg-container {
                justify-content: center;
                padding-top: 125px;
                padding-bottom: 99px;
                padding-right: 140px;
            }

            .video-list {
                overflow-x: unset;
                justify-content: center;
                margin-top: 0px;
                margin-bottom: 0px;

                .video-card {
                    margin-left: 30.5px;
                    margin-right: 30.5px;
                    flex-direction: column;
                    width: 346px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 30.5px;
                    }

                    &:last-of-type {
                        margin-left: 30.5px;
                        margin-right: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 261px;

                        [data-role ="thumbnail"] {
                            width: 346px;
                            height: 261px;
                        }

                        [data-role ="play-button"] {
                            img{
                            width: 69px;
                            height: 68px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 16px;

                        p {
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 1920px) {
            .cmg-container {
                justify-content: center;
                padding-top: 125px;
                padding-bottom: 115px;
                padding-right: 278px;
            }

            .video-list {
                overflow-x: unset;
                justify-content: center;
                margin-top: 0px;
                margin-bottom: 0px;

                .video-card {
                    margin-left: 36px;
                    margin-right: 36px;
                    flex-direction: column;
                    width: 407px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 36px;
                    }

                    &:last-of-type {
                        margin-left: 36px;
                        margin-right: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 307px;

                        [data-role ="thumbnail"] {
                            width: 407px;
                            height: 307px;
                        }

                        [data-role ="play-button"] {
                            img{
                            width: 80px;
                            height: 80px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 24px;

                        p {
                            font-size: 20px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 2560px) {
            .cmg-container {
                justify-content: center;
                padding-top: 125px;
                padding-bottom: 78px;
                padding-right: 524px;
            }

            .video-list {
                overflow-x: unset;
                justify-content: center;
                margin-top: 0px;
                margin-bottom: 0px;

                .video-card {
                    margin-left: 42px;
                    margin-right: 42px;
                    flex-direction: column;
                    width: 449px;

                    &:first-of-type {
                        margin-left: 0px;
                        margin-right: 42px;
                    }

                    &:last-of-type {
                        margin-left: 42px;
                        margin-right: 0px;
                    }

                    [data-role="video-player"] {
                        flex: initial;
                        height: 339px;

                        [data-role ="thumbnail"] {
                            width: 449px;
                            height: 339px;
                        }

                        [data-role ="play-button"] {
                            img{
                            width: 88px;
                            height: 88px;
                            }
                        }
                    }

                    [data-role ="title"] {
                        margin-top: 29px;

                        p {
                            font-size: 20px;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
    }
}
