﻿#hiw_page {
    .feature-section {
        padding: 67px 0;
        background-color: #F7F7F7;

        .cmg-container {
            display: flex;
            flex-direction: column;
        }


        .feature {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;

            &__image {
                margin-bottom: 10px;

                img {
                    display: none;
                }

                h2 {
                    font-family: 'Kadwa', serif;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0px;
                    font-weight: 700;
                    color: #175A80;
                }
            }

            &__content {

                p,
                a {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    line-height: 22px;
                    color: #4D4D4D;
                    margin-bottom: 0px;
                }
            }

            &:last-of-type {
                // border-bottom: 1px solid rgba($keppel, .5);
                margin-bottom: 0px;
            }
        }

        @media only screen and (min-width: 768px) {

            .feature {
                &__image {
                    display: flex;

                    img {
                        display: block;
                        width: 40px;
                        height: 40px;
                        margin-right: 24px;
                    }

                    h2 {
                        font-family: 'Kadwa', serif;
                        font-size: 18px;
                        line-height: 26px;
                    }
                }

                &__content {

                    p,
                    a {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            .cmg-container {
                flex-direction: row;
                flex-wrap: wrap;
            }

            .feature {
                width: 414px;
                margin-right: 76px;

                &__image {
                    margin-bottom: 17px;

                    img {
                        display: block;
                        width: 50px;
                        height: 50px;
                        margin-right: 20px;
                    }

                    h2 {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }

                &__content {

                    p,
                    a {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }


                &:nth-child(even) {
                    margin-right: 0px;
                }
            }
        }

        @media only screen and (min-width: 1200px) {
            .cmg-container {
                 justify-content: space-between;
            }

            .feature {
                width: 320px;
                margin-right:0px;
                margin-bottom: 0px;

                &__image {
                    margin-bottom: 17px;

                    img {
                        display: block;
                        width: 50px;
                        height: 50px;
                        margin-right: 20px;
                    }

                    h2 {
                        max-width: 237px;
                        font-size: 18px;
                        line-height: 26px;
                    }
                }

                &__content {

                    p,
                    a {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

               

                &:last-of-type {
                    margin-right: 0px;
                    margin-bottom: 0px;
                }
            }
        }

        @media only screen and (min-width: 1440px) {

            .feature {
                width: 350px;
               
            }
        }

        @media only screen and (min-width: 1920px) {

            .feature {
                width: 405px;

                &__image {
                    margin-bottom: 11px;

                    img {
                        display: block;
                        width: 50px;
                        height: 50px;
                        margin-right: 20px;
                    }

                    h2 {
                        max-width: 276px;
                        font-size: 20px;
                        line-height: 28px;
                    }
                }

                &__content {

                    p,
                    a {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

               

                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }

        @media only screen and (min-width: 2560px) {

            .feature {
                width: 450px;

            }
        }
    }
}
