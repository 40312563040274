﻿@import '../shared/palette';
@import '../shared/_mixins';

#real_estate_page {

    .hero-section {
        background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
        background-position: 10% center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;


        .cmg-container {
            display: flex;
            flex-direction: column-reverse;
        }

        .banner-text {
            padding-top: 8px;
            padding-bottom: 65px;

            h1 {
                font-family: 'Kadwa', serif;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0px;
                font-weight: 700;
                color: #175A80;
                margin-bottom: 0px;
            }

            h2 {
                font-size: 16px;
                color: #4D4D4D;
                font-weight: normal;
                margin-bottom: 40px;
                line-height: 24px;
                padding-top: 16px;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }

            .list-items {
                ul {
                    list-style-type: none;
                    list-style-position: outside;
                    margin-inline-start: 0px;
                    padding-inline-start: 0px;
                    margin-bottom: 0px;
                    // list-style-image: url(https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1608734277/AIOWebsite/Partners_Images/check.svg);
                }

                ul li {
                    display: flex;
                    margin-bottom: 19px;
                    align-items: center;

                    span {
                        font-family: 'Open Sans', sans-serif;
                        color: #4D4D4D;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        padding-left: 13.23px;
                        max-width: 90%;
                    }

                    img {
                        width: 19px;
                        height: 19px;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .banner-img {
            padding-top: 10px;
            //margin: 0px -32px;
            display: flex;
            justify-content: center;

            img {
                width: 170px;
                height: 236px;
            }
        }



        @media only screen and (min-width: 480px) {
            .cmg-container {
            }

            .banner-text {
                padding-top: 20px;

                h1 {
                    font-size: 28px;
                    line-height: 46px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 43px;
                    padding-top: 0px;
                }

                p {

                    &:first-of-type {
                        // margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-bottom: 43px;
                    }
                }

                .list-items {
                    ul li{
                        span{
                            max-width:100%;
                        }
                    }
                    }
                }

                .banner-img {
                    // margin: 0px -60px;
                    img {
                        width: 228px;
                        height: 315px;
                    }
                }
            }

            @media only screen and (min-width: 768px) {

                .cmg-container {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    padding-top: 0px;
                    padding-bottom: 0px;
                }

                .banner-text {
                    padding-top: 72px;
                    padding-bottom: 59px;
                    width: 353px;

                    h2 {
                        margin-bottom: 34.96px;
                    }

                    p {

                        &:last-of-type {
                            margin-bottom: 42px;
                        }
                    }

                    .list-items {
                        margin-right: -90px;

                        ul li {

                          
                            img {
                                width: 17px;
                                height: 17px;
                            }
                        }
                    }
                }

                .banner-img {
                    padding-top: 72px;
                    width: 247px;

                    img {
                        width: 247px;
                        height: 342px;
                    }
                }
            }

            @media only screen and (min-width: 1024px) {

                .cmg-container {
                }

                .banner-text {
                    padding-top: 72px;
                    padding-bottom: 58px;
                    width: 452px;

                    h2 {
                        margin-bottom: 42.96px;
                    }

                    p {
                        // max-width: 452px;
                    }

                    .list-items {
                        margin-right: -25px;

                        ul li {
                            span {
                            }

                            img {
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }
                }

                .banner-img {
                    width: 363px;
                    padding-top: 49px;

                    img {
                        width: 363px;
                        height: 503px;
                    }
                }
            }

            @media only screen and (min-width: 1200px) {

                .cmg-container {
                    padding-top: 0px;
                    padding-bottom: 0px;
                }


                .banner-text {
                    //padding-top: 110px;
                    padding-top: 85px;
                    padding-bottom: 110px;
                    width: 450px;

                    h1 {
                        font-size: 28px;
                    }

                    h2 {
                        font-size: 18px;
                        line-height: 28px;
                        margin-bottom: 42.96px;
                        max-width: 100%;
                    }

                    p {
                        max-width: 100%;

                        &:last-of-type {
                            margin-bottom: 42px;
                        }
                    }

                    .list-items {
                        margin-right: -25px;

                        ul li {
                            span {
                               
                                padding-left: 14.44px;
                            }

                            img {
                                width: 17px;
                                height: 17px;
                            }
                        }
                    }
                }

                .banner-img {
                    // margin-left: 170px;
                    width: 459px;
                    padding-top: 17px;

                    img {
                        width: 459px;
                        height: 634px;
                    }
                }
            }

            @media only screen and (min-width: 1440px) {
                .cmg-container {
                }


                .banner-text {
                    // padding-top: 89px;
                    padding-top: 84px;
                    padding-bottom: 89px;
                    width: 612px;

                    h1 {
                        font-size: 40px;
                        line-height: 50px;
                    }

                    h2 {
                        font-size: 20px;
                        line-height: 32px;
                        margin-bottom: 37.95px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        max-width: 612px;

                        &:first-of-type {
                            margin-bottom: 22px;
                        }

                        &:last-of-type {
                            margin-bottom: 45px;
                        }
                    }

                    .list-items {
                        ul {
                        }

                        ul li {
                            img {
                                width: 19px;
                                height: 19px;
                            }

                            span {
                                
                                padding-left: 13.23px;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }
                }

                .banner-img {
                    padding-top: 18px;
                    width: 459px;
                    // margin-left: 105px;
                    img {
                        width: 459px;
                        height: 634px;
                    }
                }
            }

            @media only screen and (min-width: 1920px) {
                .cmg-container {
                }


                .banner-text {
                    // padding-top: 112px;
                    padding-top: 93px;
                    padding-bottom: 111px;
                    width: 612px;

                    h2 {
                        font-size: 20px;
                        line-height: 32px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .list-items {

                        ul li {
                            img {
                                width: 21px;
                                height: 21px;
                            }

                            span {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }
                }

                .banner-img {
                    padding-top: 24px;
                    // margin-left: 311px;
                    width: 477px;

                    img {
                        width: 477px;
                        height: 659px;
                    }
                }
            }

            @media only screen and (min-width: 2560px) {

                .cmg-container {
                }


                .banner-text {
                    padding-top: 216px;
                    padding-bottom: 215px;
                }

                .banner-img {
                    padding-top: 16px;
                    // margin-left: 316px;
                    width: 630px;

                    img {
                        width: 630px;
                        height: 870px;
                    }
                }
            }
        }
    }
