﻿#faq_page {
    .faq-section {
        background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
        background-position: 10% center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .cmg-container {
            display: flex;
            flex-direction: column;
            padding-top: 82px;
            padding-bottom: 103px;
        }

        .card {
            background-color: transparent;
            background-clip: border-box;
            border: 0px solid rgba(0, 0, 0, 0.125);
            border-radius: unset;
            border-bottom: 1px solid #EAEAEA;
            padding-bottom: 25px;
            padding-top: 25px;

            &:first-of-type {
                padding-top: 0px;
            }

            &:last-of-type {
                border-bottom: 0px solid #EAEAEA;
            }
        }

        .card-body {
            padding: 0px;
            padding-top: 5px;
        }

        .card-header {
            padding: 0;
            margin-bottom: 0;
            background-color: transparent;
            border-bottom: 0px solid rgba(0, 0, 0, 0.125);
        }

        .btn-link {
            color: #80B2CE;
        }

        .card .card-header .btn-link[aria-expanded="true"] {

            p {
                span {
                    color: #175A80;
                }
            }
        }

        .card .card-header .btn-link[aria-expanded="false"] {

            p {
                span {
                    color: #4D4D4D;
                }
            }
        }

        .card .card-header .btn-link:before {
            // content: "\f0d7";
            content: url('https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1612303388/AIOWebsite/Shared_Images/Arrow_Down.svg');
            /*font-family: 'Font Awesome 5 Free';
            font-weight: 900;*/
            float: left;
            margin-right: 10px;
            margin-top: -4px;
        }

        .card .card-header .btn-link.collapsed:before {
            content: url('https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1612300155/AIOWebsite/Shared_Images/Bullet_Points.svg');
            margin-right: 10px;
            margin-top: -4px;
        }

        h1 {
            font-family: 'Kadwa', serif;
            font-size: 24px;
            line-height: 46px;
            letter-spacing: 0px;
            font-weight: 700;
            color: #175A80;
            margin-bottom: 0px;
        }

        h2 {
            font-size: 16px;
            color: #4D4D4D;
            font-weight: normal;
            margin-bottom: 28px;
            line-height: 24px;
        }

        p {
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            max-width: 100%;
            margin-bottom: 0px;
            margin-left: 18px;

            span {
                font-weight: 700;
                margin-bottom: 5px;
            }
        }

        a {
            color: #9AC125;
        }

        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
            // background-position: 0 center;
        }

        @media only screen and (min-width: 480px) {
            .cmg-container {
                padding-top: 108px;
                padding-bottom: 120px;
            }

            h1 {
                font-size: 28px;
                //line-height: 32px;
            }

            h2 {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 31.95px;
            }

            p {
            }
        }

        @media only screen and (min-width: 768px) {

            .cmg-container {
                padding-bottom: 72px;
            }

            p {
                max-width: 630px;
            }
        }

        @media only screen and (min-width: 1024px) {

            .cmg-container {
            }

            p {
                max-width: 675px;
            }
        }

        @media only screen and (min-width: 1200px) {
            .cmg-container {
                padding-top: 244px;
            }

            h1 {
                font-size: 28px;
            }

            h2 {
                font-size: 18px;
                line-height: 28px;
                max-width: 675px;
            }
        }

        @media only screen and (min-width: 1440px) {
            .cmg-container {
                padding-top: 244px;
            }

            h1 {
                line-height: 50px;
            }

            p {
                max-width: 675px;
            }
        }

        @media only screen and (min-width: 1920px) {
            .cmg-container {
                padding-top: 319px;
            }

            h1 {
                font-size: 40px;
                line-height: 50px;
            }

            h2 {
                font-size: 20px;
                line-height: 32px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                max-width: 675px;
            }
        }

        @media only screen and (min-width: 2560px) {
            .cmg-container {
                padding-top: 367px;
                padding-bottom: 187px;
            }

            p {
                max-width: 675px;
            }
        }
    }
}
