@mixin wrapper($width, $mx-auto: false) {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;

    @if $mx-auto == true {
        margin: 0 auto;
    }

    @media only screen and (min-width: 576px) {
        max-width: 475px;
    }

    @media only screen and (min-width: 768px) {
        max-width: 100%;
        padding-left: 60px;
        padding-right: 60px;
    }

    @media only screen and (min-width: 1024px) {
        max-width: 100%;
        padding-left: 60px;
        padding-right: 60px;
    }

    @media only screen and (min-width: 1200px) {
        //max-width: $width + 120px;
        max-width: 100%;
        padding-left: 100px;
        padding-right: 100px;
    }

    @media only screen and (min-width: 1440px) {
        //max-width: $width + 120px;
        max-width: 100%;
        padding-left: 140px;
        padding-right: 140px;
    }

    @media only screen and (min-width: 1920px) {
        max-width: 1920px;
        //max-width: 100%;
        padding-left: 278px;
        padding-right: 278px;
    }

    @media only screen and (min-width: 2560px) {
        max-width: 2560px;
        //max-width: 100%;
        padding-left: 524px;
        padding-right: 524px;
    }
}

@mixin size($width, $height) {
	width: $width;
	height: $height;
}
