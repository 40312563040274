﻿$white: #FFFFFF;
$black: #000000;

$grey: #808080; // #828282;
$charcoal: #4D4D4D;
$deep-see: #138890;
$bahia: #9AC125;
$green: #86BC40;
$oxford-blue: #2A343D;
$dim-grey: #707070;
$jumbo: #79787A;
$white-smoke: #E9E9E9;
$suva-grey: #8B8B8B;
$conifer: #B1D34E;
$silver: #C4C4C4;  //#C0C0C0
$dark-grey: #A8A8A8;



$java: #279CA4;
$grass: #02C04A;
$atlantis: #AED539;
$silver-light: #F7F7F7;
$alto: #CECECE;
$keppel: #32A4AC;

