﻿@import '../shared/palette';
@import '../shared/_mixins';


    .button-section {
        background-color: #F4F4F4;

        .cmg-container {
            padding-top: 28px;
            padding-bottom: 28px;
            display: flex;
            justify-content: center;
        }

        .divButtons {
            display: flex;
            flex-direction: column;
            width: 100%;

            a {
                width: 100%;

                &:first-of-type {
                    margin-bottom: 16px;
                }
            }
        }



        @media only screen and (min-width: 768px) {

            .divButtons {
                display: flex;
                flex-direction: row;

                a {
                    &:first-of-type {
                        margin-bottom: 0px;
                        margin-right: 18px;
                    }
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            .cmg-container {
                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
            }

            .divButtons {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                a {
                    width: 165px;

                    &:first-of-type {
                        margin-bottom: 0px;
                        margin-right: 19.74px;
                    }
                }
            }
        }

        @media only screen and (min-width: 1200px) {
            .cmg-container {
                margin-top: 166px;
            }

            .divButtons {
            }
        }

        @media only screen and (min-width: 1440px) {
            .cmg-container {
            }
        }
    }

