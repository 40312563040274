﻿#webinar_ll_page {
    .webinar-section {
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #F7F7F7;

        .cmg-container {
            display: flex;
            flex-direction: column;
        }

        .one {
            width: 100%;
        }

        .two {
            width: 100%;
        }

        .three {
            width: 100%;
        }

        .button-cmg {
            padding: 12px 33px;
        }

        .item {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            .webinar-head {
                display: flex;
                align-items: center;
                background: #F7F7F7 0% 0% no-repeat padding-box;
                border: 1px solid #C9C9C9;
                border-bottom: 0px solid #C9C9C9;
                border-radius: 5px 5px 0px 0px;
                padding: 0px 0px 0px 30px;
                height: 49px;

                .info-header {
                    display: flex;
                    flex-direction: column;
                }
            }

            .info {
                display: flex;
                flex-direction: row;

                img {
                    width: 18px;
                    // height: 16px;
                    margin-right: 8px;
                }

                p {
                    margin-top: 0px;
                }
            }

            .div-button {
                margin-top: 20px;

                a {
                    width: 100%;
                }
            }

            .webinar-body {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #C9C9C9;
                border-radius: 0px 0px 5px 5px;
                padding: 30px;
            }

            p {
                color: #4D4D4D;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0px;
                font-weight: normal;
                margin-bottom: 0px;

                span {
                    font-weight: 700;
                }
            }

            &:last-of-type {
                margin-bottom: 0px;
            }
        }

        @media only screen and (min-width: 480px) {
            padding-top: 60px;
            padding-bottom: 60px;

            .item {

                .webinar-head {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 0px 0px 0px 30px;
                    height: 62px;
                }

                .info {
                    img {
                        margin-right: 8px;
                    }
                }

                .div-button {
                    margin-top: 0px;
                    margin-left: 0px;

                    a {
                        width: 130px;
                    }
                }

                .webinar-body {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 30px;
                }
            }
        }

        @media only screen and (min-width: 768px) {
            padding-top: 85px;
            padding-bottom: 85px;

            .item {

                .webinar-head {
                    display: flex;
                    flex-direction: row;
                    padding: 0px 0px 0px 30px;

                    .info-header {
                        flex-direction: row;
                        align-items: center;
                    }
                }

                .info {
                    margin-right: 24px;

                    img {
                    }

                    p {
                        margin-bottom: 0px;
                    }

                    .marginBottom {
                        margin-bottom: 0px;
                    }
                }

                .div-button {
                    margin-top: 0px;
                }

                .webinar-body {
                }
            }
        }

        @media only screen and (min-width: 1024px) {

            .cmg-container {
                flex-direction: row;
                justify-content:space-between;
            }

            .one {
                width: 49%;
            }

            .two {
                width: 49%;
            }

            .three {
                width: 32%;
            }

            .item {
                margin-bottom: 0px;
               // margin-right: 32px;
              //  width: 280px;

                .webinar-head {
                    display: flex;
                    flex-direction: row;
                    height: 49px;

                    .info-header {
                        flex-direction: row;
                    }
                }

                .info {

                    img {
                    }

                    p {
                        margin-bottom: 0px;
                    }
                }

                .div-button {
                    margin-top: 20px;
                    width: 100%;

                    a {
                        width: 100%;
                    }
                }

                .webinar-body {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 30px;
                }

                &:last-of-type {
                   // margin-right: 0px;
                }
            }
        }

        @media only screen and (min-width: 1280px) {
            padding-top: 85px;
            padding-bottom: 85px;

            .cmg-container {
            }

            .item {
               // width: 345px;
                margin-bottom: 0px;
             //   margin-right: 23px;

                .webinar-head {
                    height: 62px;
                    justify-content: space-between;
                }

                .info {
                    margin-right: 0px;

                    img {
                    }

                    p {
                        margin-bottom: 0px;
                    }
                }

                .div-button {
                    margin-top: 0px;
                    width: unset;

                    a {
                        width: 130px;
                    }
                }

                .webinar-body {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px 30px;
                }

                &:last-of-type {
                   // margin-right: 0px;
                }
            }
        }

        @media only screen and (min-width: 1440px) {
            padding-top: 85px;
            padding-bottom: 85px;

            .cmg-container {
            }

            .item {
              //  width: 370px;
                margin-bottom: 0px;
              //  margin-right: 25px;

                .webinar-head {
                    justify-content: space-between;
                }

                .info {

                    img {
                    }

                    p {
                        margin-bottom: 0px;
                    }
                }

                .div-button {
                    margin-top: 0px;
                }
            }
        }

        @media only screen and (min-width: 1920px) {
            padding-top: 85px;
            padding-bottom: 85px;

            .cmg-container {
            }

            .item {
              //  width: 435px;
                margin-bottom: 0px;
              //  margin-right: 30px;

                .webinar-head {
                    justify-content: space-between;
                }

                .info {

                    img {
                    }

                    p {
                        margin-bottom: 0px;
                    }
                }

                .div-button {
                    margin-top: 0px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        @media only screen and (min-width: 2560px) {
            padding-top: 85px;
            padding-bottom: 85px;

            .cmg-container {
            }

            .item {
              //  width: 485px;
                margin-bottom: 0px;
                margin-right: 30px;

                .webinar-head {
                    justify-content: space-between;
                }

                .info {

                    img {
                    }

                    p {
                        margin-bottom: 0px;
                    }
                }

                .div-button {
                    margin-top: 0px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}
