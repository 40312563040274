﻿.video-container-widget {
    padding: 45px 0;
    background-color: #F7F7F7;

    .cmg-container {
        flex-direction: column;
    }

    [data-role="video-player"] {

        &:not(:first-of-type) {
            margin-top: 35px;
        }

        [data-role ="thumbnail"] {
            height: 225px;
        }

        .title {
            width: 225px;
            background-color: #7F7F7F;
            font-size: 22px;
            font-weight: 600;
            font-family: 'Kadwa', serif;
            color: #FFFFFF;
            text-align: center;
            margin-bottom: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    @media only screen and (min-width: 768px) {

        .cmg-container {
            flex-direction: row;
            justify-content: center;
        }

        [data-role="video-player"] {
            flex: 0 1 50%;

            &:not(:first-of-type) {
                margin-top: 0;
                margin-left: 30px;
            }

            [data-role ="thumbnail"] {
                height: 225px;
                max-width: 310px;
            }

            .title {
                width: 225px;
            }
        }
    }

    @media only screen and (min-width: 992px) {

        [data-role="video-player"] {

            [data-role ="thumbnail"] {
                height: 310px;
                max-width: 430px;
            }

            .title {
                width: 310px;
            }
        }
    }

    @media only screen and (min-width: 1200px) {

        .cmg-container {
            //justify-content: center;
        }

        [data-role="video-player"] {

            &:not(:first-of-type) {
                margin-left: 60px;
            }

            [data-role ="thumbnail"] {
                height: 410px;
                max-width: 580px;
            }


            .title {
                width: 580px;
            }
        }
    }
}
