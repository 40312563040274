﻿@import '../shared/palette';
@import '../shared/_mixins';

#webinar_retail_page, #webinar_tpo_page, #webinar_sp_page, #webinar_jv_page {
    .hero-section {
        background-image: url("https://res.cloudinary.com/dvbdysuf5/image/upload/q_auto,f_auto/AIOWebsite/Shared_Images/interior-bg.png");
        background-position: 10% center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;


        .cmg-container {
            display: flex;
            flex-direction: column-reverse;
            overflow-x: hidden;
        }

        .banner-text {
            padding-top: 29px;

            h1 {
                font-family: 'Kadwa', serif;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0px;
                font-weight: 700;
                color: #175A80;
                margin-bottom: 8px;
            }

            h2 {
                font-size: 16px;
                color: #4D4D4D;
                font-weight: normal;
                margin-bottom: 28px;
                line-height: 24px;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 50px;
                }
            }
        }

        .banner-img {
            padding-top: 22px;
            margin: 0px -42px;

            img {
                width: 100%;
            }
        }

        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
            background-position: 0 center;
        }

        @media only screen and (min-width: 480px) {
            .cmg-container {
            }

            .banner-text {
                padding-bottom: 0px;

                h1 {
                    font-size: 28px;
                    line-height: 40px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 28px;
                }

                p {

                    &:first-of-type {
                        // margin-bottom: 0px;
                    }

                    &:last-of-type {
                        margin-bottom: 50px;
                    }
                }
            }

            .banner-img {
                margin: 0px -5px;
            }
        }

        @media only screen and (min-width: 768px) {
            .cmg-container {
                flex-direction: column;
            }

            .banner-text {
                padding-top: 108px;
                padding-bottom: 0px;

                h2 {
                    margin-bottom: 34.96px;
                    max-width: 457px;
                }

                p {
                    max-width: 457px;

                    &:last-of-type {
                        margin-bottom: 20px;
                    }
                }
            }

            .banner-img {
                padding-top: 0px;
            }
        }

        @media only screen and (min-width: 1024px) {
            .cmg-container {
                flex-direction: row;
                justify-content: space-between;
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .banner-text {
                padding-top: 108px;
                padding-bottom: 108px;
                width: 470px;

                h1 {
                    max-width: 100%;
                }

                h2 {
                    margin-bottom: 35px;
                    max-width: 100%;
                }

                p {
                    max-width: 450px;
                    max-width: 432px;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            .banner-img {
                margin: unset;
                padding-top: 65px;
                margin-left: -50px;

                img {
                    width: 480px;
                    height: 345px;
                }
            }
        }

        @media only screen and (min-width: 1200px) {
            .cmg-container {
                padding-top: 0px;
                padding-bottom: 0px;
            }


            .banner-text {
                padding-top: 244px;
                padding-bottom: 116px;
                width: 660px;

                h1 {
                    font-size: 28px;
                    max-width: 100%;
                }

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 35px;
                    max-width: 100%;
                }

                p {
                    max-width: 100%;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            .banner-img {
                padding-top: 151px;

                img {
                    width: 590px;
                    height: 403px;
                }
            }
        }


        @media only screen and (min-width: 1440px) {
            .cmg-container {
                padding-top: 0px;
                padding-bottom: 0px;
            }


            .banner-text {

                h1 {
                    font-size: 28px;
                    line-height: 50px;
                    margin-bottom: 0px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 29px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;

                    &:first-of-type {
                        margin-bottom: 17px;
                    }
                }
            }

            .banner-img {
                img {
                    width: 605px;
                    height: 403px;
                }
            }
        }

        @media only screen and (min-width: 1920px) {
            .cmg-container {
                padding-top: 0px;
                padding-bottom: 0px;
            }


            .banner-text {
                padding-top: 319px;
                padding-bottom: 121px;
                width: 670px;

                h1 {
                    font-size: 40px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 32px;
                    margin-bottom: 29px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 628px;


                    &:first-of-type {
                        margin-bottom: 17px;
                    }
                }
            }

            .banner-img {
                padding-top: 194px;

                img {
                    width: 100%;
                    height: 466px;
                }
            }
        }

        @media only screen and (min-width: 2560px) {
            .cmg-container {
                padding-top: 0px;
                padding-bottom: 0px;
            }


            .banner-text {
                padding-top: 367px;
                padding-bottom: 121px;

                p {
                    max-width: 628px;
                }
            }

            .banner-img {
                padding-top: 193px;

                img {
                    width: 100%;
                    height: 515px;
                }
            }
        }
    }
}
