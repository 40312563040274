﻿@import '_mixins';
@import 'palette';

html {

    body {
        font-family: 'Open Sans', sans-serif;        

        &.blured {
            overflow: hidden;

            .body {
                filter: blur(6px);
            }
        }

        &.menu-white {

            @media only screen and (min-width: 1200px) {

                .site-navigator {
                    display:flex;
                    height:122px;
                   // box-shadow: 0 .125rem .25rem rgba($black, 0.075);
                    background-color: $white;

                    nav {

                        .dropdown-menu {
                            background-color: rgba($white-smoke, 1);
                            border: #707070 !important;
                            margin-top: 5px;

                            a {

                                &:hover {
                                    background-color: rgba($black, 0.2);
                                }
                            }
                        }
                    }
                }

                .body {
                    margin-top: 100px;
                }
            }
        }

        &.menu-noborder {

            @media only screen and (min-width: 1200px) {
                .site-navigator {
                    box-shadow: none;
                    background-color: transparent;
                }

              
            }
        }

        .body {
            margin-top: 70px;

            @media only screen and (min-width: 1200px) {
                margin-top: 45px;
            }
        }

        .cmg-container {
            display: flex;
            @include wrapper(1440px, true);
        }
    }
}



////background-attachment: fixed;
///*Edge*/
//@supports ( -ms-accelerator:true )
//{
//    html{
//        overflow: hidden;
//        height: 100%;
//    }
//    body{
//        overflow: auto;
//        height: 100%;
//    }
//}
///*Ie 10/11*/
//@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
//{
//    html{
//        overflow: hidden;
//        height: 100%;
//    }
//    body{
//        overflow: auto;
//        height: 100%;
//    }
//}

//common styles

h1,
h2,
h3,
h4,
h5,
h6 {
    //font-weight: 700;
    font-family: 'Kadwa', serif;
    color: $charcoal;
}

p {
    font-family: 'Open Sans', sans-serif;
}

a {
    text-decoration: none !important;
}
