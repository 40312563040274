﻿@import '../palette';
@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

footer {
    .footnote-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 17px;
        margin-bottom: 16.82px;

        .cmg-container {
            display: flex;
            justify-content: center;
        }

        p {
            font-family: 'Open Sans', sans-serif;
            color: #828282;
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            margin-bottom: 0px;
            text-align:center;
            max-width:95%;
        }

        @media only screen and (min-width: 480px) {
            margin-top: 17px;
            margin-bottom: 16px;
        }

        @media only screen and (min-width: 768px) {
            margin-top: 24px;
            margin-bottom: 23.82px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 24px;
            margin-bottom: 23px;

            p {
                line-height: 20px;
            }
        }

        @media only screen and (min-width: 1440px) {
            margin-top: 24px;
            margin-bottom: 23px;

            p {
                line-height: 20px;
            }
        }
    }
}

   

       
   
