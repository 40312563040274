﻿@import "mixins";

.modal {

    &.show {
        .modal-dialog {
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }
    }

    .modal-body {
        padding: 2px;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
    }

    &--video {

        video,
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            @include size(100%, 100%);
        }

        @media only screen and (min-width: 768px) {

            .modal-dialog {
                max-width: 768px;
            }
        }
    }
}
