﻿#embed_code_page, #embed_iframe_code_page {
    .aio-simulator__info {
        display: none;
    }

    .custom-select {
        height: auto !important;
        border: 0 !important;
        padding: 0 !important;
    }


    .aio-simulator {
        padding: 0px;
    }



    .form-section {

        form {
            display: flex;
            flex-direction: column;
        }

        input[type="radio"], input[type="checkbox"] {
            box-sizing: border-box;
            padding: 0;
            margin-left: 10px;
            margin-top: 0px;
        }

        .actions {
            border: 1px solid #ced4da;
            margin-top: 50px;
            padding: 30px;

            h2 {
                font-size: 16px;
                color: #4D4D4D;
                font-weight: normal;
                margin-bottom: 28px;
                line-height: 24px;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                color: #4D4D4D;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                max-width: 100%;

                span {
                    font-weight: 700;
                    font-size: 14px;
                }

                &:first-of-type {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 50px;
                }
            }
        }

        .action-row {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > div {
                margin-bottom: 30px;
            }

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
        /* Style the buttons that are used to open and close the accordion panel */
        .collapse-title {
            font-family: 'Kadwa', serif;
            font-size: 16px;
            letter-spacing: 0px;
            font-weight: 700;
            color: #175A80;
            cursor: pointer;
            padding: 18px;
            width: 100%;
            text-align: left;
            border: none;
            outline: none;
            transition: 0.4s;
            margin-bottom: 20px;
            background-color: #EFEFEF;
        }
        /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
        .active, .collapse-title:hover {
            background-color: #ccc;
        }

        .collapse-title:after {
            content: url(https://cloud.cmgfi.com/dvbdysuf5/image/upload/AIOWebsite/Shared_Images/chevron-down.svg);
            /*content: '\02795';*/ /* Unicode character for "plus" sign (+) */
            float: right;
            margin-left: 5px;
        }

        .active:after {
            /*content: "\2796";*/ /* Unicode character for "minus" sign (-) */
            content: url(https://cloud.cmgfi.com/dvbdysuf5/image/upload/AIOWebsite/Shared_Images/chevron-up.svg);
        }

        .panel {
            height: auto;
            max-height: auto !important;
            padding: 0 18px;
            background-color: white;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
        }

        .form-group {
            display: inline-block;
        }

        .button-row {
            input {
                width: 100%;
            }

            a {
                margin-top: 20px !important;
                width: 100% !important;
            }
        }

        .form-group .tooltiptext {
            font-size: 14px;
            line-height: 22px;
            margin-left: 10px;
            width: 320px;
            visibility: hidden;
            background-color: #FFF;
            border-radius: 4px;
            border: 1px solid #aeaeae;
            position: absolute;
            z-index: 1;
            padding: 5px;
            margin-top: -50px;
            opacity: 0;
            transition: opacity 0.5s;
        }

        .form-group .tooltiptext::after {
            content: " ";
            position: absolute;
            top: 5%;
            right: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #aeaeae transparent transparent;
        }


        .form-group:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
        }

        .cmg-container {
            display: flex;
            flex-direction: column;
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .form-row, .form-row1 {
            display: flex;
            flex-direction: column;
        }

        label {
            font-size: 14px;
            font-weight: 600;
        }



        @media only screen and (min-width: 375px) {
        }

        @media only screen and (min-width: 425px) {
            // background-position: 0 center;
        }

        @media only screen and (min-width: 480px) {
            .cmg-container {
            }
        }

        @media only screen and (min-width: 768px) {

            .cmg-container {
                padding-bottom: 72px;
            }

            .action-row {
                flex-direction: row;
                align-items:flex-start;

                > div {
                    margin-bottom: 0px;
                    margin-right: 30px;
                }
            }

            .form-row {
                display: -ms-flexbox;
                display: flex;
                flex-direction: row;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin-right: 0px;
                margin-left: 0px;
                justify-content: flex-start;
            }

            .button-row {
                input {
                    width: auto !important;
                }

                a {
                    margin-top: 0px !important;
                    margin-left: 20px !important;
                    width: auto !important;
                }
            }

            .form-row1 {
                display: -ms-flexbox;
                display: flex;
                flex-direction: row;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin-right: 0px;
                margin-left: 0px;
                justify-content: space-between;
            }

            .form-group {
                width: 20%;
                margin-right: 5%;
                /* align-items: center; */
                justify-content: center;
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;
                /* vertical-align: middle; */
            }
        }

        @media only screen and (min-width: 1024px) {

            .cmg-container {
                flex-direction: column;
            }
        }

        @media only screen and (min-width: 1200px) {
            .cmg-container {
            }
        }

        @media only screen and (min-width: 1440px) {
            .cmg-container {
            }
        }

        @media only screen and (min-width: 1920px) {
            .cmg-container {
            }
        }

        @media only screen and (min-width: 2560px) {
            .cmg-container {
            }
        }
    }
}
